import React, { Component } from 'react';

import IsVisible from '../../_core/components/IsVisible';

//import Swiper from 'swiper';
import { withRouter } from "react-router-dom";

import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Controller,EffectCoverflow,Mousewheel,Virtual,Autoplay } from 'swiper';

//import 'swiper/css/swiper.css';
import './swiper.css';

//import 'swiper/swiper.scss';
//import 'swiper/components/effect-coverflow/effect-coverflow.scss';

SwiperCore.use([EffectCoverflow,Mousewheel,Virtual,Autoplay]);

// Polyfill for Element.closest()
if (!Element.prototype.matches) {
  Element.prototype.matches = Element.prototype.msMatchesSelector || 
                              Element.prototype.webkitMatchesSelector;
}

if (!Element.prototype.closest) {
  Element.prototype.closest = function(s) {
    var el = this;

    do {
      if (el.matches(s)) return el;
      el = el.parentElement || el.parentNode;
    } while (el !== null && el.nodeType === 1);
    return null;
  };
}

//const Swiper = window.Swiper;

class SwiperComponent extends Component {

  busy = false;
  ref = false;
  api = false;
  defaultParams = {
        // Optional parameters
        direction: 'horizontal',
        loop: true,
        mousewheel:{
          forceToAxis:true,
          invert:false
        },
        preventClicksPropagation :true,
        // If we need pagination
        pagination: {
          el: '.swiper-pagination',
        },

        // Navigation arrows
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        watchSlidesVisibility : true,
        // And if we need scrollbar
        scrollbar: {
          el: '.swiper-scrollbar',
        },

        on: {
          init: () => {
            
          },
          click: (event) => {
            
            return false;
            

            
            return false;
          }
        },

        
      }

  componentDidMount = () => {





  }

  componentWillUnmount = () => {

      this.busy = false;
      //this.api.destroy();

  }

  navigate = (event) => {
    let el = event.target;
    if(el.tagName == 'A'){
      let url = el.getAttribute('href');
      let isExternal = (url.match('http'));
      if(isExternal){
        window.open(url,'_blank','noopener');
      }else{
        this.props.history.push(url);
      }
    }
    return false;
    if(this.busy){
        event.stopPropagation();
        event.preventDefault();
        return false;
      }

      this.busy = true;
      setTimeout(()=>{
          this.busy = false;
      },400);

      let element = event.target;
      
      let datahref = element.getAttribute('data-href');
      let url = (element.tagName == 'A' && ((typeof datahref == 'undefined') || datahref == null)) ? element.getAttribute('href') : datahref;

      //console.log(element,datahref);
      let slide = element.closest('.swiper-slide[style]');

      if(slide){
        let active = slide.className.match('swiper-slide-active');

        var children = Array.prototype.slice.call(slide.parentElement.children);

        if(!active){
            
            let ix = children.indexOf(slide);
            this.api.slideTo(ix);

            setTimeout(()=>{
              
               this.api.loopFix();
               this.api.update();

            },600);

        }else{

          //specific element that was clicked i.e.: p or img tag 
          if(typeof url != 'undefined' && url !== null && url != ""){
            event.stopPropagation();
            event.preventDefault();
            this.props.history.push(url);
            return false;
          }
        }
      }else{
        // No slide
        if(typeof url != 'undefined' && url !== null && url != ""){
            event.stopPropagation();
            event.preventDefault();
            this.props.history.push(url);
            return false;
          }

      }

  }

  setRef = (el) => {

      this.ref = el;
      let propsParams = this.props.params || {};
      let params = {...this.defaultParams,...propsParams};

      this.api = new Swiper (this.ref, params)

  }

  setAPI = (instance) => {
    
    this.api = instance;

    let initSlide = this.props.params.initialSlide || 0;

    if(this.props.params.effect == 'coverflow'){
    setTimeout(()=>{

      this.api.slideTo(initSlide,false,false);
      
    }
      ,100);
    }
  }

  render () {

    if(!this.props.children.length) return null;

    //const [swiperAPI, setSwiperAPI] = useState(null);
    let propsParams = this.props.params || {};
      let params = {...this.defaultParams,...propsParams};
      /*<div onClick={(ev)=>{ev.preventDefault();ev.stopPropagation();this.navigate(ev)}}>
      {<div className="swiper-container" ref={this.setRef}>

          <div className="swiper-wrapper">*/

// Old swiper


          /*</div>

          <div className="swiper-pagination"></div>


          <div className="swiper-button-prev"></div>
          <div className="swiper-button-next"></div>

          <div className="swiper-scrollbar"></div>
      </div>
      </div>*/

      if(this.props.params.effect == 'coverflow' && this.props.children.length <= 3) params.loop = false;

      
    return(
         
          <div onClick={(ev)=>{ev.preventDefault();ev.stopPropagation();this.navigate(ev)}}>
          <Swiper
      {...params}
      onSwiper={this.setAPI}
      virtual={(params.slidesPerView == 'auto' || params.loop) ? false : true}
      className="swiper-container"
      
    >
              {this.props.children.map((child,ix)=>(
                  <SwiperSlide className="swiper-slide" virtualIndex={ix} key={`swiper_slide_${ix}`}>{child}</SwiperSlide>
              ))}
              </Swiper>
              </div>
         

          
    )

  }
}

 
export default withRouter(SwiperComponent);