import React, { Component } from 'react';
import '../../_core/helpers/String'; // .decodeHTML prototype
import Link from '../../_core/components/Link';
import SiteModifier from '../../_core/components/SiteModifier';
import Html from '../../_core/components/Html';
import Icon from '../../_core/components/Icon';
import Data from '../../_core/models/Data';
import {withStore} from '../../_core/utils/Store';

class ClosePlayer extends Component {

	close = () => {

		this.props.store.store('SHOWPLAYER',false);
		// Timeout to all panel to close
		setTimeout(()=>{

			this.props.store.store('PLAYER',false);

		},1000);


	}
  render () {

  	let modifier = this.props.modifier || 'default';

    return (
		<Data>
		{
			({structures}) => {
        
				
				return (
				<div className="Link" onClick={this.close}><Icon glyph type={'play'} /></div>
				)
			}

		}
		</Data>
    )
  }
}

export default withStore(ClosePlayer)
