import React, {Component} from 'react'
import Base from '../BaseComponent'
import Image from '../Image'
import Video from '../Video'
import Gallery from '../Gallery'
import Asset from '../../models/Asset'
import Content from '../Content';

import { DefaultPlayer as ReactVideo } from 'react-html5video';
import 'react-html5video/dist/styles.css';
import './video-styles.css';

class Media extends Component {


  render () {


  	let settings = {};
    let title = "";
	   let _this = this;

     

    if(this.props.url) {

        if(_this.props.fluid == false){
            let ratio = this.props.ratio || 300/200;
                settings = {ratio:ratio};
        }else{
                settings = {fluid:true};
        }

        if(this.props.title) {
            title = this.props.title;
        }

        return  (<Image {...this.props} {...settings } title={title} small={this.props.url} large={this.props.url} />)

    }

    if(this.props.videoId)
    {
        return (
        <Asset id={[this.props.videoId]} render={(item) => {

                    if(_this.props.fluid == false){
                let ratio = this.props.ratio || item.width/item.height;
                        settings = {ratio:ratio};
                    }else{

                        settings = {fluid:true};

                    }


                    return  (<Video {...settings } src={item.sizes.local} />)

            }


        }/>

    )
     }else {
        let id = parseInt(this.props.id);
        let _this = this;

        if(this.props.asset){
          return _this.renderMedia(this.props.asset);
        }

        return (
        <Asset id={[id]} render={(item) => {

                return _this.renderMedia(item);

        }}/>
        )
     }


  }

  renderMedia = (i) => {
      let settings = {};
      let _this = this;

      let lazy = 1;
      if(_this.props.hasOwnProperty('lazy') && (_this.props.lazy === false)) lazy = false;

      // Default schema for an asset
      let schema = {
        id:0,
        src:'',
        sizes : {},
        filename : '',
        inlineVideo:0,
        title:'',
        alt:'',
        extension:''
      }

      let item = {...schema, ...i};

      if(this.props.debug){
        console.log('DEBUG MEDIA',item);
      }

      if(!item.hasOwnProperty('sizes')) item.sizes = {};

      let orientation = null;
      if(item.width && item.height){
        orientation = (item.width > item.height) ? 'landscape' : 'portrait';
      }

      let caption = (_this.props.captions) ? item.caption : null;
      if(_this.props.caption) caption = _this.props.caption;

                if(_this.props.fluid == false){
                let ratio = this.props.ratio || item.width/item.height;
                  settings = {ratio:ratio};
                }else{
                  settings = {fluid:true};
                }

                if(item.hasOwnProperty('videoUrl') && item.videoUrl && item.videoUrl != ''){

                    let videoStyle;
                    if(settings.fluid){

                        videoStyle = {

                            position:'absolute',
                            top:0,
                            left:0,
                            width:'100%',
                            height:'100%',
                             overflow:'hidden'

                        }

                    }else{

                        videoStyle = {
                            position:'relative',
                            height:0,
                            overflow:'hidden',
                            paddingTop : (100 / (settings.ratio)) + '%',
                            overflow:'hidden'

                        }

                    }

                    let fit = this.props.fit || 'cover';

                    return (
                      <div className={"Video Video--"+((item.inlineVideo == 1)?'inline':'controls')} style={videoStyle}>

                          <div className="Video-player" style={{width:'100%',height:'100%',position:'absolute',top:0,left:0}}>
        
                            {(()=>{

                                if(item.inlineVideo == 1){
                                    return (<video src={item.videoUrl} playsInline poster={item.url} autoPlay loop={'true'} muted={'true'} width="100%" height="100%" style={{width:'100%',height:'100%','objectFit':fit}}></video>)
                                }else{

                                    let custom = this.props.custom || false;


                                    if(custom){

                                      let controls = this.props.controls || ['PlayPause', 'Seek', 'Time', 'Volume', 'Fullscreen']
                                      
                                      return (

                                          // https://www.npmjs.com/package/react-html5video
                                          // autoPlay loop muted
                                          <div className={'Video-custom'}>
                                          <ReactVideo 
                                            controls={controls}
                                            poster={item.url}
                                            src={item.videoUrl}
                                            onCanPlayThrough={() => {
                                                // Do stuff
                                            }}>
                                          </ReactVideo>
                                          </div>

                                      )
                                    }else{
                                      
                                      return (<video src={item.videoUrl} controls width="100%" height="100%" poster={item.url} controlsList="nodownload" style={{width:'100%',height:'100%','objectFit':fit}}></video>)
                                    }
                                }

                            })()}
                            
                            
                          </div>
                      </div>)

                }

                if(item.imageAnimation && item.imageAnimation.length){

                    let delay = item.animationSpeed;
                    let images = [item.id,...item.imageAnimation];

                    let galleryStyle;

                    if(settings.fluid){

                        galleryStyle = {

                            position:'absolute',
                            top:0,
                            left:0,
                            width:'100%',
                            height:'100%',


                        }

                    }else{

                        galleryStyle = {
                            position:'relative',
                            height:0,
                            overflow:'hidden',
                            paddingTop : (100 / (settings.ratio)) + '%'

                        }

                    }

                    let gallerySettings = {...settings};
                    gallerySettings.fluid = true;

                    return (<Asset id={images} results={(assets)=>(

                          <div style={galleryStyle}>

                          <Gallery modifier='gif' fluid getItem={(galleryItem)=>(
                            <Image

                            {..._this.props}
                            {...gallerySettings }
                            lazy={lazy}
                            orientation={orientation}
                            xs={(galleryItem.sizes.xs||null)}
                            thumb={(galleryItem.sizes.thumb||null)}
                            small={(_this.props.original) ? galleryItem.sizes.local : galleryItem.sizes.small}
                            medium={(_this.props.original) ? galleryItem.sizes.local : galleryItem.sizes.medium}
                            large={(_this.props.original) ? galleryItem.sizes.local : galleryItem.sizes.large}
                            svg={(galleryItem.filename.indexOf('.svg') > 0 ? galleryItem.sizes.original : null)}
                          />)}
                          images={assets}
                          autoplay={true}
                          delay={delay} />
                          </div>


                      )} />)

                }

                return  (
                  <div>
                  <Image

                      {...i}
                      {...this.props}
                      {...settings }

                      lazy={lazy}
                      orientation={orientation}
                      xs={(item.sizes.xs||null)}
                      thumb={(item.sizes.thumb||null)}
                      small={(_this.props.original) ? (item.sizes.local||item.src) : (item.sizes.small||item.src)}
                      medium={(_this.props.original) ? (item.sizes.local||item.src) : (item.sizes.medium||item.src)}
                      large={(_this.props.original) ? (item.sizes.local||item.src) : (item.sizes.large||item.src)}
                      svg={(item.filename.indexOf('.svg') > 0 ? item.sizes.original : null)}
                    />
                    {(
                      () => {
                        if(caption){
                          return (<div className="Image-caption"><Content html={caption} /></div>);
                        }
                      }
                    )()}
                    </div>
                )
                

  }
}

export default Media
