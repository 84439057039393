import React, { Component } from 'react';

// Conf
import conf from './_core/_conf';

// Core
import Loader from './_core/components/Loader';
import FadeIn from './_core/components/FadeIn';
import SiteModifier from './_core/components/SiteModifier';
import Site from './_core/utils/Site';

// Project
import Router from './components/Router';
import Intro from './components/Intro';
import LoadProgress from './components/LoadProgress';

import * as Browser from './_core/components/ModernBrowser'

class Index extends Component {


  constructor(props){

      super(props);
  }

  render() {

    // Load the settings to get the manifest (JSON data to power the site)
    let settings = conf.get();
    
    return (
      <React.Fragment>
      <Site>
        <SiteModifier modifier="loading" auto />

        <Intro />

        <Browser.Modern>
          {
            ()=> (
              <Loader wait={1000} manifest={settings.MANIFEST} transformer={(store) => {

                // This property gets called after all teh manifest has loaded
                // But before it's all published
                // So it's the last chance to transform the whole store before the state changes

                // So What's going on here?
                // Well to reduce load on the CMS, shows data is split across 10 json files
                // The rest of the entries are in pages.json
                // However the app expects an 'entries' property in data with everything altogether
                // So...
                // We are building the entries prop by concatinating the pages data with the 10 buckets of shows data
                // We then return an object with the entries prop populated
                // The placholder buckets emptied as they are redundant

                // How many bucket are the shows spread over?
                const SHOW_BUCKETS = 10;

                // Blank entries array we will stitch buckets onto
                let entries = [];
                // Mak an n-length array for each show bucket we have in the store
                let shows = [...Array(SHOW_BUCKETS)].map((i,ix)=>`shows${ix +1}`);

                // Add teh show bucket labels to the pages labels
                // These are the keys we will be accessing in the store
                let buckets = ['pages'].concat(shows);

                // What we will return
                let returnObj = {};

                // Make an empty array for each bucket in the return object so we are not storing things in duplicate - we don't need the buckets once we've combined the entries
                buckets.forEach((bucket) => {
                  returnObj[bucket] = [];
                  // Add the contents of this bucket onto the entries array
                  entries = entries.concat(store[bucket]);
                });

                // Create a key in teh return object for teh newly created concatinated entries
                returnObj['entries'] = entries;

                // Return it to be added to the store...
                return returnObj;

              }} progress={(progress)=>{
                return <div className="Site-loader"><LoadProgress progress={progress} /></div>
              }} loaded={

                  ()=>(
                  
                    <React.Fragment>
                      <div className="Site-loader"><LoadProgress progress={100} /></div>
                      <SiteModifier modifier="loaded" auto />
                      <FadeIn duration={0.3}>
                        <Router />
                      </FadeIn>
                    </React.Fragment>
                  
                  )

              }/>
            )
          }
          
        </Browser.Modern>
        <Browser.Old>
          {
            () => {
                // Set html flag to basic mode, don't return the app
                document.documentElement.className = "basic-mode Html--loaded" + document.documentElement.className.replace("rich-mode", "");
                return null;

            }
          }
        </Browser.Old>
      </Site>
      </React.Fragment>
    );
  }
}



export default Index;
