import React, { Component } from 'react';

import cookieAPI from './_cookieAPI.js';
import CookieToggle from './CookieToggle';
import Cookies from './Cookies';

import './Cookies.css';

class CookieManager extends Component {

	constructor(props) {
		super(props);
		this.state = {};
	}


	/*

		RENDER

	*/

	render() {

		let com = this;

		return(
		<Cookies key={document.cookie}>
			
			{({cookieAPI,cookies,cookieSettings})=>{

				let keys = Object.keys(cookies);
				let cookiesList = {};
				if(!keys.length){
					keys = ['No cookies set'];
					cookiesList = {'No cookies set':''};
				}else{
					cookiesList = cookies;
				}

				return (

					<table className="Cookie-table" >
					<tbody>
					<tr>
					   <th>Cookie</th>
					   <th>Value</th>
					 </tr>
					{keys.map((k,ix)=>{

						return (
							<tr key={`cookie_${ix}`}>
								<td>{k}</td>
								<td>{cookiesList[k]}</td>
							</tr>
						)

					})}
					</tbody>
					</table>
				)

			}}

		</Cookies>)


	
		

	}
}

export default CookieManager;