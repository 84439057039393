import Data from './Data'
import SetData from './SetData'

export default Data

export {

	Data,
	SetData

}
