import React, { Component, Children } from "react"
import PropTypes from 'prop-types'; 
import Emitter from "emitter.js";
import {Data} from '../models';
import {withStore} from './Store.js';

import "../helpers/Array";

class Site extends Component {

modifiers = [];
state = {'modifiers': [],width:window.innerWidth,height:window.innerHeight,data:{}};

constructor(props){

  super(props);
}
static Emitter = new Emitter();

  static propTypes = {
    emitter: PropTypes.object,
    site:PropTypes.object
  }
  // you must specify what you’re adding to the context
  static childContextTypes = {
    emitter: PropTypes.object,
    site:PropTypes.object
  }

componentWillMount() {

  if(this.props.initModifiers) this.modifiers = this.props.initModifiers;
  //window.addEventListener('resize',this.resize);


}

componentWillUnmount() {

  //window.removeEventListener('resize',this.resize);

}

resize = () => {

  this.setState({width:window.innerWidth,height:window.innerHeight});

}

store = (key,value) => {

    let data = {...this.state.data};
    data[key] = value;

    this.setState({data:data});

}

componentDidMount() {

  var _this = this;
  // Can trigger events via event trigger
  Site.Emitter.on('modify',function(modifier){


    _this.modify(modifier);

  });

  // Expose site API to window
  global.site = this;
  global.Site = Site;
}

  // Here's where the values are exported to any components that have registered an interest in the context
  getChildContext() {
    
   return { emitter:Site.Emitter ,site:this}
  }

  hasModifier = (modifier) => {
    let submitted = modifier.split(',');
    let currentModifiers = this.modifiers;
    let matches = submitted.filter((m) => { return (currentModifiers.indexOf(m) != -1) } );
    return (matches.length > 0)

  }

  modify = (modifier,update=true) =>{
    //if(this.modifiers.indexOf(modifier) != -1) return;
     let submitted = modifier.split(',');
     let currentModifiers = this.modifiers;
     let add = submitted.filter((m) => { return (currentModifiers.indexOf(m) == -1) } );



    const modifiers = [...this.modifiers,...add]

    this.modifiers = modifiers;
    if(update) this.updateModifiers();
    //this.setState({'modifiers':modifiers});
    
  }

  toggle = (modifier,update=true,detoggle) =>{

    
    if(this.modifiers.indexOf(modifier) == -1){

        if(detoggle) this.deModify(detoggle,false);
        this.modify(modifier,update);

    }else{

        if(detoggle) this.modify(detoggle,false);
        this.deModify(modifier,update);

    }
    
  }

  deModify = (modifier,update=true) =>{

    
    let remove = modifier.split(',');
 
    const modifiers = this.modifiers.filter((m) => {return (remove.indexOf(m) == -1) });
    
    // Check if nothing removed
    if(this.modifiers.length == modifiers.length) return;

     this.modifiers = modifiers;

        if(update) this.updateModifiers();
     
    //this.setState({'modifiers':modifiers});
    
  }

  updateModifiers = () => {

      let modifiers = [...this.modifiers];
     
      let classList = this.classes();
      
      if(this.refs.site){
          this.refs.site.className = classList;
          
          let origClasses = document.documentElement.className.replace('Html ','').replace(/Html--[^\s]+/g,'');
          document.documentElement.className = origClasses + ' ' + classList.replace(/Site/g,'Html');
          
      }else setTimeout(this.updateModifiers,0);

      //let compare = [...this.state.modifiers];

      /*
      if(!modifiers.sort().compare(compare.sort())){
          //console.log('DO',modifiers,compare)
          //this.setState({'modifiers':this.modifiers});

          
      }else{

          console.log('DONT',modifiers,compare)

      }

      //this.setState({'modifiers':this.modifiers});

      */

  }

  modifierClasses = () => {

    return (this.modifiers.map((m) => {
        return 'Site--'+m
    })
    .join(' '));

  }

  modifierProps = () => {
    if(!this.props.modifiers) return '';
     
      return (this.props.modifiers.map((m) => {
        return 'Site--'+m
    })
    .join(' '));
    
  }

  utilModifiers = () => {
    
    const utils = [];

    let touch = ((('ontouchstart' in window)
      || (navigator.MaxTouchPoints > 0)
      || (navigator.msMaxTouchPoints > 0)
      )) ? 'touch' : 'no-touch';
    utils.push(touch);

    let root = document.getElementsByTagName( 'html' )[0];
      root.classList.add(touch)


   
     
      return (utils.map((m) => {
        return 'Site--'+m
    })
    .join(' '));
    
  }


  routeModifiers = () => {

    if(!this.props.routeModifier) return '';
    return (this.props.routeModifier.split('/').map((m) => {
        if(m == '') return '';
        return 'Site--'+m
    })
    .join(' '));

  }

  classes(){

      return 'Site ' + this.modifierClasses() +' '+ this.modifierProps() + ' ' + this.routeModifiers()  + ' ' + this.utilModifiers();

  }

  render() {

    //document.documentElement.className = this.classes().replace(/Site/g,'Html');

    // `Children.only` enables us not to add a <div /> for nothing
    //return Children.only(this.props.children)
    return <div ref="site">{this.props.children}</div>
  }
}

/*

withSite()
- Add site as props to any component exported wrapped in withSite()
- export default Component = withSite(Component);

*/

export const withSite = (ComponentToWrap) => {
  return class WrapComponent extends Component {
    // let’s define what’s needed from the `context`
    static contextTypes = {
      emitter : PropTypes.object,
      site: PropTypes.object
    }
    render() {
      
      const { emitter,site } = this.context
      // what we do is basically rendering `ComponentToWrap`
      // with an added `theme` prop, like a hook
      return (
        <ComponentToWrap {...this.props} site={site} emitter={emitter} />
      )
    }
  }
}

// Note this is wrapped in the withSite() function, which adds the site as props to the component automatically
export const SiteModifier_ = withSite(
  class SiteModifier_ extends Component {
  static defaultProps = {
    
    }

    componentWillMount = () =>{ 
      // Allow immediate modification
    if(this.props.auto || !this.props.children) this.handleClick();

    }

    shouldComponentUpdate = (nextProps) =>{

      
      return true;
      //console.log('SITEMODIFIER SHOULD',nextProps,this.props)
      return ((nextProps.modifier !== this.props.modifier) || (nextProps.demodifier !== this.props.demodifier))

    }
    componentDidUpdate = (nextProps) =>{

      // Allow immediate modification
      //if(this.props.auto) console.log('SITEMODIFIER DID',nextProps,this.props)
      if(this.props.auto && this.props.hasOwnProperty('update')){
        //console.log('disable auto check',this.props.update,nextProps.update,this.props.update == nextProps.update);
        if(this.props.update == nextProps.update) return;
      }
      if((this.props.auto || !this.props.children) && ((nextProps.modifier !== this.props.modifier) || (nextProps.demodifier !== this.props.demodifier))){
          //console.log('SITEMODIFIER','APPLY',this.props.demodifer,'off',this.props.modifier,'on')
          
          this.handleClick();

      }

    }

    handleClick  = (ev) => {


      let _this = this;

      let delay = this.props.delay || 0;
      delay = parseInt(delay);

      // Use milliseconds
      if(delay < 100) delay *= 1000;

      if((delay && !ev) || (delay && this.props.forceDelay)){
        
        setTimeout(function(){

            _this.modify();


        },delay)
      }else{
       
        _this.modify();

      }
    }

    modify = () => {

      const {site} = this.props;

      if(this.props.demodifier) site.deModify(this.props.demodifier,false);
      if(this.props.modifier) site.modify(this.props.modifier,false);

      if(this.props.toggle) site.toggle(this.props.toggle,false,this.props.detoggle);


      site.updateModifiers();
      

    }
      


    handleHoverOn  = () => {

      const {site} = this.props;

      let modifier = this.props.hovermodifier || this.props.modifier;
      let demodifier = this.props.hoverdemodifier || this.props.demodifier;
      if(modifier) site.modify(modifier);
      if(demodifier) site.deModify(demodifier);

      //site.updateModifiers();

    }

    handleHoverOff  = () => {

      const {site} = this.props;

      let modifier = this.props.hovermodifier || this.props.modifier;
      let demodifier = this.props.hoverdemodifier || this.props.demodifier;
      if(modifier) site.deModify(modifier);
      if(demodifier) site.modify(demodifier);

      //site.updateModifiers();

    }

    render() {
      if(!this.props.children) return null;
    const { children, ...propsToPass } = this.props
    let _this = this;

    

      const child = React.Children.only(children);

      const props = {};
      if(this.props.hover){

        props.onMouseEnter = this.handleHoverOn;
        props.onMouseLeave = this.handleHoverOff;

        if(this.props.click){
          props.onClick = this.handleClick;
        }

      }else{

        props.onClick = this.handleClick;

      }



      return React.cloneElement(child, {...props, ...propsToPass});

    
    }
  });



export default Site;