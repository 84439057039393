import React, { Component } from 'react';

import Link from '../../_core/components/Link';
import Content from '../../_core/components/Content';
import Media from '../../_core/components/Media';
import SiteModifier from '../../_core/components/SiteModifier';
import Data from '../../_core/models/Data';

class Footer extends Component {

  render () {

    return (
		<Data>
		{
			({structures}) => {


				return (
          
                <ul className="List List--partners">
                      {structures.find(m=>m.slug == 'partners').sets[0].links.map((item,ix)=>(
                          <li key={'partner_'+ix}><Link navlink to={item.uri}><Media id={item.image} fluid={false} /></Link></li>
                      ))}
                  </ul>


				)
			}

		}
		</Data>
    )
  }
}

export default Footer
