import React, { Component } from 'react';
import * as Media from '../../utils/Breakpoints';

class Video extends Component {

	constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {



	}

	componentWillUnmount() {

	  }



  render() {

	const ratioStyle = {

  		paddingTop:(100 / (this.props.ratio))+'%'

  	}
  	const fluidStyle = {

  		width:'100%',
  		height:'100%',
      position:'absolute',
      top:0,
      left:0

  	}

  	const style = (this.props.fluid) ? fluidStyle : ratioStyle;

    let className = this.props.className || 'video';

    //if(this.props.fluid) className += " Image--fluid";


    return (
      <div className={'Image '+className} ref="image" style={style}>
        <video webkit-playsinline="true" playsInline autoPlay loop muted>
          <source src={this.props.src} type="video/mp4" />
        </video>
      </div>
    );
  }
}

export default Video;
