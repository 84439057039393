import React, {Component} from 'react'

const Tidy = ({html,modifier,children}) => {
  const mod = (modifier) ? 'Content--'+modifier : '';

  let props = {};
  let content = null;
  if(html) props.dangerouslySetInnerHTML = {__html:html};
  if(children && !html) content = children;

  return <div {...props}>{content}</div>

}

export default Tidy;
