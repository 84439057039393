
Array.prototype.shuffle = function(){

    let array = this;
    let counter = array.length;

    // While there are elements in the array
    while (counter > 0) {
        // Pick a random index
        let index = Math.floor(Math.random() * counter);

        // Decrease counter by 1
        counter--;

        // And swap the last element with it
        let temp = array[counter];
        array[counter] = array[index];
        array[index] = temp;
    }

    return array;
}

// Return if 2 arrays are different
Array.prototype.compare = function(testArr) {
    if (this.length != testArr.length) return false;
    for (var i = 0; i < testArr.length; i++) {
        if (this[i].compare) { //To test values in nested arrays
            if (!this[i].compare(testArr[i])) return false;
        }
        else if (this[i] !== testArr[i]) return false;
    }
    return true;
}

Array.prototype.limit = function(number) {
    let arr = [...this];

    return arr.splice(0,number);
  }

Array.prototype.first = function() {
    return this[0];
  }

Array.prototype.where = function(key,value) {
    let arr = [...this];
    return arr.filter((i)=>(i[key] == value));

  }

// See if this array has any value in another array

/**
 * @description determine if an array contains one or more items from another array.
 * @param {array} haystack the array to search.
 * @param {array} arr the array providing items to check for in the haystack.
 * @return {boolean} true|false if haystack contains at least one item from arr.
 */
Array.prototype.contains = function (haystack) {

    let arr = [...this];

    return arr.some(function (v) {
        return haystack.indexOf(v) >= 0;
    });
};

// Array chunker - return array in n groups
Array.prototype.chunkify  = function(n,balanced){
    
    let a = this;
    
    if (n < 2)
        return [a];

    let len = a.length,
            out = [],
            i = 0,
            size;

    if (len % n === 0) {
        size = Math.floor(len / n);
        while ( i < len) {
            out.push(a.slice(i, i += size));
        }
    }

    else if (balanced) {
        while (i < len) {
            size = Math.ceil((len - i) / n--);
            out.push(a.slice(i, i += size));
        }
    }

    else {

        n--;
        size = Math.floor(len / n);
        if (len % size === 0)
            size--;
        while (i < size * n) {
            out.push(a.slice(i, i += size));
        }
        out.push(a.slice(size * n));

    }

    return out;
    
}

// Prop sort - sort an array of objects by an object prop
Array.prototype.propSort = function(prop, direction){
   
    let direct = (typeof direction != 'undefined') ? direction : 1; //Default to ascending

    if(typeof prop != 'string') return;
    
    let propPath = (prop.constructor===Array) ? prop : prop.split(".");

    this.sort(function(a,b){
        
       
        
        for (var p in propPath){
            if (a[propPath[p]] && b[propPath[p]]){
                a = a[propPath[p]];
                b = b[propPath[p]];
            }
        }
        
         // Convert to string
        a = a+'';
        b = b+'';
        
        // convert numeric strings to integers
        a = a.match(/^\d+$/) ? +a : a;
        b = b.match(/^\d+$/) ? +b : b;

        return ( (a < b) ? -1*direct : ((a > b) ? 1*direct : 0) );
    });
    
    return this;
}



