import React, { Component } from 'react';

//import {PageElement,Template,SiteModifier,Content} from '../_core/components';

import PageElement from '../_core/components/PageElement';
import Template from '../_core/components/Template';
import Content from '../_core/components/Content';
import SiteModifier from '../_core/components/SiteModifier';
import FadeIn from '../_core/components/FadeIn';
import Link from '../_core/components/Link';

// Models
import {Asset,Entry,Data,SetData} from '../_core/models';

// Project
import * as Project from '../components';

import Results from './results';


class Default extends Component {

	constructor(props) {
		super(props);
	}

	componentDidMount() {

	}

	componentWillUnmount() {

	}

	/*

		RENDER

	*/

	render() {

		
		return (
	
				<Template modifiers={['search']} title={'Search'}>

				
				
				{/* Reset styles */}
				<SiteModifier auto demodifier={'scrolled'} />
				<SiteModifier auto demodifier={'home'} />

				<Data>
				{
					({entries}) => {

						//let tags = tags = entries.filter(e=>(e.type == 'tags'));

						let tagged = entries.filter(e=>e.type == 'listing').reduce((tagged,entry)=>{
							let copy = [...tagged];
							let unique = (entry.tags) ? entry.tags.filter(t=>(copy.indexOf(t) == -1)) : [];
							let combined = copy.concat(unique);

							
							return combined;
						},[]);

						let tags = entries.filter(e=>((e.type == 'tags') && (tagged.indexOf(e.id) != -1 )));

						//let tagIds = tags.map((t)=>(t.id));
						let tagIds = tagged;
						
								return (
									<React.Fragment>
									<div className="Template-head">
										<input type={'text'} placeholder={'Search...'} />
									</div>
									<div className="Template-nav">
										<ul className="List List--tags">
											{tags.map((t,ix)=>(
												<li key={'tag'+ix}><Link navlink to={t.uri}>{t.title}</Link></li>
											))}
										</ul>
									</div>
									{(()=>{

										if(this.props.children){
											return this.props.children;
										}else{

											let settings = {

												title : '',
												source : 'taggedWith',
												related : tagIds,
												text : '',
												callToAction : 'More info',
												type:'results',
												style:'results',
												template:'tags',
												filter : (entry) => {
													return entry.type == 'listing'
												}
												
											}

											return <Results entry={settings} />

										}

									})()}
									</React.Fragment>
								);

							
					}
				}
				</Data>
	
			
				</Template>
		)

	}
}

export default Default;
