import React, { Component } from 'react';
import Swiper from '../Swiper';

class Slider extends Component {

  render () {

    let params = {
                      slidesPerView: 1,
                      spaceBetween: 1,
                      loop:true,
                      grabCursor: true,
                      centeredSlides: true,
                      speed:600,
                      pagination: {
                        el: '.swiper-pagination',
                        //type : 'fraction'
                      },
                      autoplay: false
                    }

  if(this.props.autoPlay){
    params.autoplay = {
      delay: 3000
    }
  }

  if(this.props.hasOwnProperty('preventClicksPropagation')){
    params.preventClicksPropagation = this.props.preventClicksPropagation;
  }
 
  return(
    <Swiper params={{...params}}>
      {this.props.items.map(

          (item,ix) => (
            <div key={`slide_${ix}`} className="swiper-slide">{item}</div>
          )

      )}
    </Swiper>
  )
  }
}

 
export default Slider;