import React, {Component} from 'react'

const Nl2br = ({text,content,children}) => {
 
  let txt = text || content || children ;
  if(!txt) return null;
  let output = [];
  let split = txt.split('\n');
  let replaced = split.map((item, key) => {
	  output.push(item);
	  if(key < (split.length - 1)) output.push(<br key={'br'+key} />);
	})

  return output;

}

export default Nl2br;
