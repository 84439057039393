import React, { Component } from "react";
import "../../_core/helpers/Array"; // .shuffle prototype
import "../../_core/helpers/String"; // .decodeHTML prototype
import Link from "../../_core/components/Link";
import SiteModifier from "../../_core/components/SiteModifier";
import Html from "../../_core/components/Html";
import Gallery from "../../_core/components/Gallery";
import Data from "../../_core/models/Data";

import Logo from "../Logo";

let words = ["Culture", "Debate", "Art", "Loading", "Music"].shuffle();

class Intro extends Component {
  render() {
    // let modifier = this.props.modifier || 'default';

    return (
      <SiteModifier modifier="open">
        <div className="Intro" key={"intro"}>
          <h1 className="Intro-logo">
            <span>RTM.FM</span>
            <Logo />
          </h1>
          <h2 className="Intro-quote">
            <Gallery
              autoplay
              wait={0}
              delay={200}
              items={words}
              getItem={(word) => {
                return <div>{word}</div>;
              }}
            />
          </h2>
        </div>
      </SiteModifier>
    );
  }
}

export default Intro;
