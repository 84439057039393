import React, { Component } from 'react';

import {
  withRouter
} from 'react-router-dom';

import {withStore} from '../../utils/Store';
import '../../helpers/String';

/*
    <DataRouter routes={[
    	// Basic: return an object for a direct match
    	{
    	route:'/tags/#/furnishings/',
    	data : {tagId:21}
    	},
    	// Dynamic, run a lookup and return the data for the match
    	{
    	route:'/tags/#/(.*)/',
    	data : (matches,store) => {
			
			let tag = store.tags.find(tag=>(tag.id == matches[1]))
			return {'tagId':tag.id,'tagTitle':tag.title};


    	}
    	}

    ]}>

    </DataRouter>
    */

class DataRouter extends Component {

    hasInit = 0;

	constructor(props) {
		super(props);
		this.state = {};
	}

	componentDidMount(props) {
    let hash = (this.props.location.hash) ? this.props.location.hash : '';
      const page = this.props.location.pathname+hash;

      this.setData(page);
    }

    componentWillReceiveProps(nextProps) {
      let hash = (this.props.location.hash) ? this.props.location.hash : '';
      let nextHash = (nextProps.location.hash) ? nextProps.location.hash : '';

      const currentPage = this.props.location.pathname + hash;
      const nextPage = nextProps.location.pathname + nextHash ;

      if (currentPage !== nextPage || !this.hasInit) {
        this.setData(nextPage);
      }
    }

    /*
    <DataRouter routes={[
    	// Basic: return an object for a direct match
    	{
    	route:'/tags/#/furnishings/',
    	data : {tagId:21}
    	},
    	// Dynamic, run a lookup and return the data for the match
    	{
    	route:'/tags/#/(.*)/',
    	data : (matches,store) => {
			
			let tag = store.tags.find(tag=>(tag.id == matches[1]))
			return {'tagId:tag.id,'tagTitle',tag.title};


    	}
    	}

    ]}
    */

    setData = (uri) => {

        if(this.props.store.isEmpty() && (!this.props.hasOwnProperty('allowEmpty'))) return null;

        this.hasInit = 1;
    	let matched = 0;
    	let _this = this;

    	//console.log('DATAROUTER',uri,this.props.routes);

        if(this.props.reset){

            matched = 1;
            this.props.reset.forEach(label=>_this.props.store.unstore(label));

        }

    	if(this.props.routes){

    		this.props.routes.forEach((r)=>{

                let match = uri.uriMatch(r.route);

    			//console.log('DATAROUTER MATCH',r,match);

    			if(match){

    				let d;
    				matched = 1; // We have something to store...

    				if(typeof r.data == 'function'){
    					d = r.data(match,_this.props.store.state.data);
    				}else{

    					d = r.data;

    				}

    				for(let k in d){
    					if(d.hasOwnProperty(k)){
    						// Convert {'tagId':22} in store('tagId',22,{noPublish})
    						_this.props.store.store(k,d[k],false);

    					}
    				}

    			}

    		});

    		

    	}

        if(matched){

            _this.props.store.publish(); // Update the store...
            //console.log(_this.props.store.get('SNUFFKIN'),_this.props.store.state.data.SNUFFKIN);

        }

    }

	componentDidMountOLD() {

		

		let hash = this.props.location.hash.replace('#','');

		//console.log('MODAL LOC~ATION',this.props);

		let request = hash.split('?');
		let template = request[0];
		let query = request[1];
		let args = (query) ? query.split('&') : [];

		//console.log('DATAROUTER',args);

		let params = args.reduce((cur,arg) => {
			
			let pair = arg.split('=');
			
			cur[pair[0]] = (pair[1].match(',')) ? pair[1].split(',').map((i)=>(decodeURIComponent(i.replace('-',' ')))) : decodeURIComponent(pair[1].replace('-',' '));
			return cur;

		},{});

		//console.log('DATAROUTER',params);
		
		for(let k in this.props.map){

			if(this.props.map.hasOwnProperty(k)){

				let dotAddress = this.props.map[k].split('.');

				let label = dotAddress.shift();

				let obj = {}
				let val;

				if(dotAddress.length){
					val = {};
					let indexToSet = dotAddress.length - 1;
					dotAddress.forEach((i,ix)=>{

						val[i] = (ix == indexToSet) ? params[k] : {}

					});

				}else{
					val = params[k];
				}

				this.props.datastore.store(label,val,false); // false flag - don't publish

			}

		}
		
		this.props.datastore.publish();
		
	}

	componentWillUnmount() {

	}

	/*

		RENDER

	*/

	render() {
        
        //console.log('DR render',this.props.store.isEmpty());
        if(this.props.store.isEmpty() && (!this.props.hasOwnProperty('allowEmpty'))) return null;
		return this.props.children;

	}
}

export default withStore(withRouter(DataRouter));
