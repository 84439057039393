import React, { Component } from 'react';
import Content from '../_core/components/Content';

// Project
import Blocks from '../components/Blocks';


class Default extends Component {

	constructor(props) {
		super(props);
	}

	componentDidMount() {

	}

	componentWillUnmount() {

	}


	/*

		RENDER

	*/

	render() {

		let entry = this.props.entry;
		let blocks = entry.blocks || [];

		let content = [...blocks];
	

		return (
			<React.Fragment>
			<div className="Template Template--default">
				<div className="Template-content">
					<div className="Template-head">
						<Content>
							<h1>{entry.title}</h1>
						</Content>
					</div>
					<div className="Template-body">
						<Content html={entry.content} />
					</div>
				</div>
				<div className="Template-blocks">
					<Blocks blocks={content} entry={entry} modifiers={['debug']} />
				</div>
			</div>
			
			</React.Fragment>

		);

	}
}

export default Default;
