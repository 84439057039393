import React, { Component } from 'react';
import Base from '../BaseComponent'

import preloader  from 'preloader';
import {withStore} from '../../utils/Store';
import {withSite} from '../../utils/Site';


const HASLOADED = 0;
const d = new Date();
const CACHEBUSTER = d.getFullYear()+'-'+d.getMonth()+'-'+d.getDay()+'-'+d.getHours()+'-'+d.getTime();

class Loader extends Component {

  start=0;

  constructor(props) {
    super(props);
    this.state = {loaded:0,progress:0};
  }

  hasLocalStorage = () => {
    
  var testKey = 'test', storage = window.localStorage;
  try {
    storage.setItem(testKey, '1');
    storage.removeItem(testKey);
    return true;
  } catch (error) {
    return false;
  }

}

  componentDidMount() {

      const _this = this;

    
 

    _this.start = new Date().getTime();

    let loader = preloader({
      xhrImages: false
    });

    let load = 0;
    let assetsLoaded = [];
    let contentLoaded = [];

    let loaded = 0;
    let progress = 0;

    loader.on('progress',function(progress,filename) {

        if(assetsLoaded.indexOf(filename) == -1){
          assetsLoaded.push(filename);
          loaded++;
          progress = loaded / load;

         _this.setState({progress:progress});
       }
    });

    loader.on('complete',function() {

      _this.setState({progress:1});
      assetsLoaded.forEach((i) => {

          let src = (i.src) ? i.src : i;
          let label = (i.name) ? i.name : i;

          let content = loader.get(src);

          contentLoaded[src] = content;

      });

      let hold = _this.props.hold || 50;
      setTimeout(complete,hold);


    });

    this.props.manifest.forEach((i) => {

        
        let src = (i.src) ? i.src : i;
        let label = (i.name) ? i.name : i;
        
        //console.log('LOAD',src);
        // Add a key for the final content set
        contentLoaded[src] = 0;

        let cached = 0;

        if(i.hasOwnProperty('cache') && _this.hasLocalStorage()){

            
            let storage = window.localStorage;
            cached = storage.getItem(src);

            if(cached){
              contentLoaded[src] = JSON.parse(cached);
            }

        }

        if(!cached){

        load++;

        _this.addManifestItem(i,loader);

       }
        
  

    });

    const complete = () => {

   
      let store = {};

      _this.props.manifest.forEach((i) => {

        let src = (i.src) ? i.src : i;
        let label = (i.name) ? i.name : i;

        let content = contentLoaded[src];
        if(!content) content = {};
        let toSave = (i.subset && content.hasOwnProperty(i.subset)) ? content[i.subset] : content;

        if(i.hasOwnProperty('cache') && _this.hasLocalStorage()){

            
            let storage = window.localStorage;
            storage.setItem(src,JSON.stringify(content));

        }else{

        }



        

        //console.log('save',label,toSave,contentLoaded);

        if(i.hasOwnProperty('transformer')){

            toSave = i.transformer(toSave,{...store});

        }

        store[label] = toSave;

        _this.props.store.store(label,store[label],false); // false flag - don't publish

    });

      // Allow entire store to be transformed 
      if(_this.props.transformer){

        // Check for transformer prop on the Loader
        let transformed = _this.props.transformer({...store});

        // Overwrite each key returned from the transformer
        Object.keys(transformed).forEach((k)=>{
          _this.props.store.store(k,transformed[k],false); // false flag - don't publish
        }
        );
      }
      
      _this.props.store.store('LOADED',true,false);
      _this.props.store.publish(); // Send one publish event

      let timeNow = new Date().getTime();

      let diff = timeNow - _this.start;

      let wait = _this.props.wait || 0;

      let timeout = wait - diff;

      if(timeout < 0) timeout = 0;
      
      setTimeout(function(){

        _this.setState({progress:1,loaded:1});
        if(_this.props.onLoad) _this.props.onLoad();

      },timeout);

      _this.postLoad(store);

    }

    if(_this.props.manifest.length && load){
      loader.load();
    }else{
      complete();
    }

    


  }

  addManifestItem = (i,loader,cachebuster='') => {

      if(i.hasOwnProperty('type')){

        switch(i.type){

          case 'json':
            loader.addJSON(i.src+cachebuster);
            break;
          default:
            loader.add(i.src+cachebuster);

        }

      }else{
        loader.add(i.src);
      }

  }

  postLoad = (lastStore) => {

      let _this = this;
      let load = 0;
      let postloader = preloader({
        xhrImages: false
      });



      let store = {};

  

      _this.props.manifest.forEach((i) => {

        if(i.hasOwnProperty('cache') && _this.hasLocalStorage()){
            load++;
             _this.addManifestItem(i,postloader,'?nc='+CACHEBUSTER);
            
        }

      });
      
      postloader.on('complete',function() {

        _this.props.manifest.forEach((i) => {

          let src = (i.src) ? i.src : i;
          let label = (i.name) ? i.name : i;



          if(i.hasOwnProperty('cache') && _this.hasLocalStorage()){
              let content = postloader.get(src+'?nc='+CACHEBUSTER);
              let storage = window.localStorage;
              let toSave = (i.subset) ? content[i.subset] : content;

              if(content){
                  
                  storage.setItem(src,JSON.stringify(content));

              }

                if(i.hasOwnProperty('transformer')){

                    toSave = i.transformer(toSave,{...store});

                }

                store[label] = toSave;

                _this.props.store.store(label,toSave,false); // false flag - don't publish
                      
          }else{

            let content = store[label];
            let toSave = (i.subset) ? content[i.subset] : content;

            if(i.hasOwnProperty('transformer')){

                 toSave = i.transformer(toSave,{...store});

              }

              store[label] = toSave;
          }

          

        });
                console.log('postload',store);
                //_this.props.store.publish(); // Send one publish event


            });

      if(load) postloader.load();


  }

  componentWillUnmount() {

  }

  


    

  /*
    
    RENDER

  */

  render() {

    //console.log('LOADER',this.state);
   
    if(this.state.loaded){
      
      return (this.props.loaded) ? this.props.loaded(this.props.data,this.props.store) : this.props.children;
    }

    if(this.props.progress){

        return this.props.progress(Math.round(this.state.progress * 100));

    }

    return (
      <Base family="Loader">
          {this.state.progress * 100}%
      </Base>
    );

  }
}



export default withStore(Loader);
