import React, {Component} from 'react'
import Base from '../BaseComponent'

class Grid extends Component {

  render () {

    return (
      <Base {...this.props} family="Grid" >
        { this.props.children }
      </Base>
    )
  }
}

export default Grid
