import bootstrap from "./js/_core/_bootstrap.js";
import "./index.scss";

// Get the App
import App from "./js/index.js";

// Get the project settings
import settings from "./js/_settings.js";

bootstrap(App, settings);
