import React, { Component } from 'react';

import ViewportProgress from '../../_core/components/ViewportProgress';


class OpacityScreen extends Component {

  hasCompleted = false;
  ref = false;
  blackout = ({progress}) => {

    if(!this.ref) return;
    
    if(this.props.reverse){
      this.ref.style.opacity = Math.max(0,1-progress);
    }else{
        this.ref.style.opacity = Math.max(0,progress);
    }
    
    if(progress >= 1 && !this.hasCompleted){

      if(this.props.onComplete){

          this.hasCompleted = true;
          this.props.onComplete();

      }


    }

  }

  render () {

      let start = this.props.start || 0;

      let dist = this.props.dist || window.innerHeight;

      let useHeight = (dist) ? false : true
			return (
  			<ViewportProgress onProgress={this.blackout} start={start} distanceoff={dist} debug={false} ignoreHeight={true} useHeight={true}>
  			  <div data-screen ref={(el) => this.ref = el} />
        </ViewportProgress>
			)

  }
}

export default OpacityScreen
