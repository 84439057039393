import React, { Component } from 'react';
import '../../_core/helpers/String'; // .decodeHTML prototype
import Link from '../../_core/components/Link';
import SiteModifier from '../../_core/components/SiteModifier';
import Html from '../../_core/components/Html';
import Data from '../../_core/models/Data';
import Autosuggest from 'react-autosuggest';
import { withRouter } from "react-router-dom";

import {Item} from '../Blocks/Blocks.jsx';

import { RiSearchLine,RiCloseFill } from "react-icons/ri";

const SearchIcon = RiSearchLine;
const CloseIcon = RiCloseFill;

// https://developer.mozilla.org/en/docs/Web/JavaScript/Guide/Regular_Expressions#Using_Special_Characters
function escapeRegexCharacters(str) {
  return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}

function getSuggestions(dataset,value) {
  const escapedValue = escapeRegexCharacters(value.trim());
  
  if (escapedValue === '') {
    return [];
  }

  //  const regex = new RegExp('^' + escapedValue, 'i'); 
  const regex = new RegExp("(^|\\s|\\-)" + escapedValue, 'i');
  const startsWith = new RegExp("^" + escapedValue, 'i');

  return dataset
    .map(section => {

      let results = section.items.filter(item => regex.test(item.title)).sort((a,b)=>{

            //let aStarts = startsWith.test(a.title);
            let bStarts = startsWith.test(b.title);

            if(bStarts) return 1
            else return -1

        });



      if(section.title == 'Episodes') results = results.propSort('createdDate',-1).slice(0,10);

      if(['Episodes','Shows'].indexOf(section.title) != -1) results = results.slice(0,10);

      return {
        title: section.title,
        items: results
      };
    })
    .filter(section => section.items.length > 0)
}

function getSuggestionValue(suggestion) {
  return suggestion.title;
}

function renderSuggestion(suggestion) {
  
  let itemProps = {
      url : suggestion.uri,
      image : suggestion.thumbnail,
      heading : suggestion.title,
      style : suggestion.type,
      playerLink : suggestion.playerLink,
    };


  if(suggestion.type == 'tags'){
    return <ul className="List List--tags"><li><span>{suggestion.title}</span></li></ul>
  }

  if(suggestion.type == 'audio'){
    return <div key={suggestion.id}>{Item(itemProps)}</div>
  }

   if(suggestion.type == 'listing'){
    return <div key={suggestion.id}>{Item(itemProps)}</div>
  }

  return (
   <span>{suggestion.title}</span>
  );
}

function renderSectionTitle(section) {
  return (
    <strong>{section.title}</strong>
  );
}

function getSectionSuggestions(section) {
  return section.items;
}

class Search extends React.Component {
  constructor() {
    super();

    this.state = {
      value: '',
      suggestions: [],
      active: false
    };    
  }

 storeInputReference = (autosuggest) => {
    if (autosuggest !== null) {
      this.input = autosuggest.input;
    }
  }

  onChange = (event, { newValue, method }) => {
    this.setState({
      value: newValue
    });
  };
  
  onSuggestionsFetchRequested = ({ value }) => {
    this.setState({
      suggestions: getSuggestions(value)
    });
  };

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: []
    });
  };

  onSuggestionSelected = (event, { suggestion, suggestionValue, index, method }) => {
        event.preventDefault();
        
        if(this.input) this.input.blur();

        this.setState({
          suggestions: [],
          value : '',
          active:false
        },()=>{
          this.props.history.push(suggestion.uri);  
        });
    

    };

  render() {
    const { value, suggestions } = this.state;
    const inputProps = {
      placeholder: "Search...",
      value,
      onChange: this.onChange
    };

    let active = this.state.active ? 'active' : 'inactive';

    return (

      <Data>
    {
      ({entries,tags}) => {

        let dataset = [
          {
            title : 'Shows',
            items : entries.filter(e=>((e.type == 'listing')))
          },
          {
            title : 'Episodes',
            items : entries.filter(e=>((e.type == 'audio')))
          },
          {
            title : 'Pages',
            items : entries.filter(e=>((e.type == 'pages')))
          },
          {
            title : 'Tags',
            items : entries.filter(e=>((e.type == 'tags')))
          }
        ];


        return (
          <div className={`Search Search--${active}`}>
            <div className="Search-body">
              <Autosuggest
              ref={this.storeInputReference}
              multiSection={true}
              suggestions={suggestions}
              onSuggestionsFetchRequested={({value}) => {

                  this.setState({
                    suggestions: getSuggestions(dataset,value)
                  });

              }}
              onSuggestionsClearRequested={this.onSuggestionsClearRequested}
              getSuggestionValue={getSuggestionValue}
              renderSuggestion={renderSuggestion}
              renderSectionTitle={renderSectionTitle}
              getSectionSuggestions={getSectionSuggestions}
              onSuggestionSelected={this.onSuggestionSelected}
              inputProps={inputProps}

               />
             </div>
             <div className="Search-icon" onClick={()=>{
                this.setState({active:true})
                if(this.input) this.input.focus();
             }}>
              <SearchIcon />
             </div>
             <div className="Search-close" onClick={()=>{
                this.setState({active:false})
                if(this.input) this.input.blur();
             }}>
              <CloseIcon />
             </div>
           </div>
        )

    }}
    </Data>

      
    );
  }
}

export default withRouter(Search);
