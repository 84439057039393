import React, {Component} from 'react'

import Site, {SiteModifier_} from '../../utils/Site';

const Element = window.Element;

if (!Element.prototype.matches) {
  Element.prototype.matches = Element.prototype.msMatchesSelector || 
                              Element.prototype.webkitMatchesSelector;
}

if (!Element.prototype.closest) {
  Element.prototype.closest = function(s) {
    var el = this;

    do {
      if (el.matches(s)) return el;
      el = el.parentElement || el.parentNode;
    } while (el !== null && el.nodeType === 1);
    return null;
  };
}

class Toggle extends Component {

  active = false;
  el = null;
  constructor(){

      super();
     

  }
  componentDidMount = () => {

      let el = (this.props.for) ? document.querySelector(this.props.for) : this.refs.el;
      let className= (this.active) ? 'is-active' : 'is-inactive';
      el.classList.add(className);

      this.el = el;

      
      if(!el.getAttribute('id')){
        let id = 'toggle_'+ Math.ceil(Math.random() * 1000);
        el.setAttribute('id',id);
      }

      if(this.props.autoclose) document.addEventListener("click", this.autoclose);

  }
  autoclose = (event) => {
      let el = this.el;
        let id = el.getAttribute('id');

          // If user clicks inside the element, do nothing
          if(event.target === el) return;
          if (event.target.closest(`#${id}`)) return;

          el.classList.remove('is-active');
          el.classList.add('is-inactive');
      

  }
  componentWillUnmount = () => {

      if(this.props.autoclose) document.removeEventListener("click", this.autoclose);

  }
  click = () => {

      
      let el = (this.props.for) ? document.querySelector(this.props.for) : this.refs.el;

      this.active = !this.active;

      el.classList.toggle("is-active");
      el.classList.toggle("is-inactive");

  }
  render () {

    let content = null;

    let Tag = (this.props.tag || "a");

   return (<Tag ref={'el'} onClick={this.click} className={this.props.className}>{this.props.children}</Tag>)
      
  }
   
}

export default Toggle
