const theme = {

    palette : [
      {
        label: 'Blue',
        hex : `#0B1E35`
      },
      {
        label: 'Black',
        hex : `#232121`
      },
      {
        label: 'Grey',
        hex : `#DAD8D6`
      },
      {
        label: 'White',
        hex : `#FFFFFF`
      }
      
      
    ],
    type : [
      {
        label : 'intro'
      },
      {
        label : 'heading'
      },
      
      {
        label : 'body'
      },
      
      {
        label : 'small'
      },
      
    ],
    icons : [
      {
        label : 'arrow-left'
      },
      {
        label : 'arrow-right'
      },
      {
        label : 'cross'
      },
      {
        label : 'instagram'
      },
      {
        label : 'facebook'
      },
      {
        label : 'twitter'
      },
      {
        label : 'plus'
      }
    ],
    assets : [
      {
        src : '/ui/img/logo.svg',
        width : 250,
        height:250
      },
      
      
    ]

  }
export default theme;