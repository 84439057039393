import React, { Component } from 'react';
import '../../_core/helpers/String'; // .decodeHTML prototype
import Link from '../../_core/components/Link';
import SiteModifier from '../../_core/components/SiteModifier';
import Media from '../../_core/components/Media';
import Html from '../../_core/components/Html';
import Icon from '../../_core/components/Icon';
import Data from '../../_core/models/Data';

import './radio.css';

const $ = window.jQuery;
const TOUCH = !!('ontouchstart' in window || navigator.maxTouchPoints);

// API: https://radio.co/radio-university/diy-player-kit

class Live extends Component {

  el=false;
  player = false;

  constructor(props) {
    super(props);

    this.state = {playing:false};

  }

  componentDidMount() {

      if(this.el){
      
          this.player = $(this.el).find('.radioplayer').radiocoPlayer();

      }


  }

  componentWillUnmount = () => {

    if(this.player){
      // Pause the palyer
      this.player.pause();
      // Remove the DOM element
      let $player = $('#radioco-radioplayer');
      if($player.length) $player.remove();

    }

  }

  render () {

    


    return (
    <Data>
    {
      ({entries}) => {

        return <div className="Item Item--radio" ref={(el) => this.el = el}>

          <div className="radioplayer"
          data-src="https://streams.radio.co/s3d2f05f8f/listen"
          data-autoplay={!TOUCH}
          data-playbutton="true"
          data-volumeslider="true"
          data-elapsedtime="false"
          data-nowplaying="true"
          data-showplayer="true"
          data-volume="50"></div>

        </div>

      }

    }
    </Data>
    )
  }
}

export default Live
