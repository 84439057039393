import React, { Component } from 'react';

import Data from '../../models/Data'

import {withStore} from '../../utils/Store';

import cookieAPI from './_cookieAPI.js';
import cookieSettings from './_cookieSettings.js';

import './Cookies.css';

class Cookie extends Component {

	constructor(props) {
		super(props);
		this.state = {};
	}

	componentDidMount() {
		
		let hasCookies = this.props.data.hasOwnProperty('cookies');
	
		if(!hasCookies){
			let cookies = cookieAPI.getCookies();
			this.props.store.store('cookies',cookies);
		}

		if(this.props.auto){

			this.set(this.props.name,this.props.value);

		}

	}

	set = (key,value) => {

		if(!value){

			cookieAPI.deleteCookie(key); // 1 Year

		}else{
			cookieAPI.setCookie(key,value,365); // 1 Year
		}
		
		let cookies = cookieAPI.getCookies();
		this.props.store.store('cookies',cookies);



	}

	remove = (key) => {


		cookieAPI.deleteCookie(key); // 1 Year
		let cookies = cookieAPI.getCookies();
		this.props.store.store('cookies',cookies);

	}

	clear = () => {

		cookieAPI.clearCookies();
		let cookies = cookieAPI.getCookies();
		this.props.store.store('cookies',cookies);

	}

	agree = () => {

		this.set(cookieSettings.AGREE_KEY,cookieSettings.AGREE_VALUE);

	}

	decline = () => {

		cookieAPI.clearCookies();
		this.set(cookieSettings.AGREE_KEY,cookieSettings.DECLINE_VALUE);

	}


	/*

		RENDER

	*/

	render() {

		let com = this;

		return(
		<Data require={['cookies']}>
			
			{({cookies})=>{

				let dnt = navigator.doNotTrack == '1' || window.doNotTrack == '1';
				let agreeCookies = (cookies.hasOwnProperty(cookieSettings.AGREE_KEY) && cookies[cookieSettings.AGREE_KEY] == cookieSettings.AGREE_VALUE);
				let declinedCookies = (cookies.hasOwnProperty(cookieSettings.AGREE_KEY) && cookies[cookieSettings.AGREE_KEY] == cookieSettings.DECLINE_VALUE);
				let noPreference = !cookies.hasOwnProperty(cookieSettings.AGREE_KEY);
			
				let rendered = (typeof this.props.children == 'function') ? this.props.children({cookieAPI:com,cookies:cookies,cookieSettings:cookieSettings,doNotTrack:dnt,agreeCookies:agreeCookies,declinedCookies,noPreference:noPreference}) : this.props.children;

		        return (rendered) ? rendered : null;


			}}

		</Data>)
		

	}
}

export default withStore(Cookie);