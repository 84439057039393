import React, {Component} from 'react'
import Data from '../../_core/models/Data'
import DataRouter from '../../_core/controllers/DataRouter'

import Layout from '../../templates/_layout';

let PAGES = 0;

class Router extends Component {

  constructor() {
    super()

  }

  

  render () {
      return (
      <DataRouter routes={[
          
          // Reset image on all routes
          {
            route:'(.*)/?',
            data : (matches,data)=> {

              PAGES++;

              // Get entry by URI
              let found = false;
              found = data.entries.find(entry=>(entry.uri == window.location.pathname));
              
              // Get homepage
              const home = (data.hasOwnProperty('HOME')) ? data.HOME : data.entries.find(entry=>(entry.uri == '/'));

              // Get posts
              const posts = (data.hasOwnProperty('POSTS')) ? data.POSTS : data.entries.filter(entry=>(entry.type == 'post'));

              //if(found) console.log('ENTRY',found);



              

              /*
                Route PANEL content
              */

              let panel = (data.hasOwnProperty('PANEL')) ? data.PANEL : false;
              let showpanel = false;

              if(found){

                  // If template is basic or page, set the panel content to be the current entry
                  if(['panel'].indexOf(found.template) != -1 || (found.hasOwnProperty('playerLink') && found.playerLink != '')){
                    panel = found;
                    showpanel = true;
                  }

              }

              

              /*
                Route overlay content
              */

              let overlay = (data.hasOwnProperty('OVERLAY')) ? data.OVERLAY : false;
              let showoverlay = false;

              if(found){

                  // If template is basic or page, set the panel content to be the current entry
                  if(['overlay'].indexOf(found.template) != -1){
                    overlay = found;
                    showoverlay = true;
                  }

              }

              /*
                Route ARTICLE content
              */

              let article = (data.hasOwnProperty('ARTICLE')) ? data.ARTICLE : false;
              let showarticle = false;

              if(found){

                  // If template is basic or page, set the panel content to be the current entry
                  if(['post'].indexOf(found.type) != -1){
                    article = found;
                    showarticle = true;

                    // First request on an article page, load the news list below
                    if(PAGES == 1){
                        let newsList = data.entries.find(entry=>(entry.template == 'news-list'));
                        if(newsList) main = newsList;
                    }
                  }

              }

              /*
                Route MAIN content
              */

              let main = (data.hasOwnProperty('MAIN')) ? data.MAIN : home;

              if(found){

                  // If template is home or news, set the panel content to be the current entry
                  if((['panel','overlay'].indexOf(found.template) == -1) && (['post'].indexOf(found.type) == -1) && !(found.hasOwnProperty('playerLink') && found.playerLink != '')) {
                    
                    main = found;
                  }else{
                    
                    // Page isn't news or home, so leave it to whatever it was before...
                  }

              }else{
                  // URI doesn't match anything, unset main to it can be routed by the controller
                  // Only update this is we haven't set the panel or overlay content
                  if(!showarticle && !showpanel && !showoverlay){
                    main = {id:false};
                  }
              }

              // Did MAIN change?
              let mainChanged = (data.hasOwnProperty('MAIN')) ? (main.id != data.MAIN.id) : false;


              return {COMPONENTS_TRIGGER:false,CATEGORY_FILTER:false,MAIN_CHANGED:mainChanged,MAIN:main,POSTS:posts,HOME:home,ENTRY:found,PANEL:panel,OVERLAY:overlay,ARTICLE:article,SHOW_PANEL:showpanel,SHOW_OVERLAY:showoverlay,SHOW_ARTICLE:showarticle}

            }
          },
          {
            route:'__guide/?',
            data : (matches,data)=> {
              return {COMPONENTS_TRIGGER:true}

            }
          },
          {
            route:'#/filter/(.*)/?',
            data : (matches,data)=> {
              
              let found = data.categories.find(c=>(c.slug == matches[1]));

              let filter = false;
              if(found) filter = found.term_id;
           
              return {CATEGORY_FILTER:filter}

            }
          },
          // Set an IMAGE variable based on a hash value
          // eg /about/#/image/1/
          {
            route:'#/play/(.*)/?',
            data : (matches,data)=> {
              
              let uri = window.location.hash.split('#/play')[1];
              
              let found = data.entries.find((e)=>e.uri == uri);
              let player = (data.hasOwnProperty('PLAYER')) ? data.PLAYER : false;
              let showplayer = (data.hasOwnProperty('SHOWPLAYER')) ? data.SHOWPLAYER : false;
              if(found){
                  player = JSON.stringify({src:found.playerLink,more:found.uri});
                  showplayer = true;
                 
              }

              return {PLAYER:player,SHOWPLAYER:showplayer}

            }
          },
          {
            route:'#id_token=(.*)/?',
            data : (matches,data)=> {
              
              return {TOKEN:matches[1]}

            }
          },
          {
            route:'reservations',
            data : (matches,data)=> {
              
              return {RESERVATIONS:true}

            }
          }
          ]}>
          <Data require={['entries','assets','structures','HOME']}>
              {()=>(

                  <Layout />
              )}
          </Data>
      </DataRouter>
      )
  }
}

export default Router
