import React, { Component } from 'react';
import '../../_core/helpers/String'; // .decodeHTML prototype
import Link from '../../_core/components/Link';
import SiteModifier from '../../_core/components/SiteModifier';
import Media from '../../_core/components/Media';
import Html from '../../_core/components/Html';
import Icon from '../../_core/components/Icon';
import Data from '../../_core/models/Data';
import {withStore} from '../../_core/utils/Store';

const $ = window.jQuery;

class Live extends Component {

  el=false;

  constructor(props) {
    super(props);

    this.state = {playing:false};

  }

  componentDidMount() {

      if(this.el){
      
          $(this.el).find('.radioplayer--nowplaying').radiocoPlayer();

      }


  }

  render () {

  	


    return (
		<Data>
		{
			({entries}) => {

				return <div className="Item Item--live" ref={(el) => this.el = el} onClick={()=>{

        this.props.store.store('PLAYER','RADIO',false);
        this.props.store.store('SHOWPLAYER',true);

      }}>

          <div className="Item-dot" />

          <div className="Item-head">Live <Icon type="play" glyph /></div>
          <div className="Item-body">
            <marquee>
              <span  className="Item-text">
                <span  className="radioplayer--nowplaying"
                data-src="https://streams.radio.co/s3d2f05f8f/listen"
                data-playbutton="false"
                data-volumeslider="false"
                data-elapsedtime="false"
                data-nowplaying="true"
                data-showplayer="false">
                </span>
              </span>
              <span  className="Item-text">
                <span  className="radioplayer--nowplaying"
                data-src="https://streaming.radio.co/s3d2f05f8f/listen"
                data-playbutton="false"
                data-volumeslider="false"
                data-elapsedtime="false"
                data-nowplaying="true"
                data-showplayer="false">
                </span>
              </span>
            </marquee>
          </div>

        </div>

			}

		}
		</Data>
    )
  }
}

export default withStore(Live);
