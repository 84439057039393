import React, {Component} from 'react'
import VisibilitySensor from 'react-visibility-sensor';
class IsVisible extends Component {

  hasSeen = 0;

  render () {

    let {children} = this.props;
    let className = this.props.className || '';

  return (
    <VisibilitySensor onChange={this.onChange} partialVisibility={true} offsetoff={{top:-100}}>
    {({isVisible}) => {

      if(isVisible){

        if(this.props.onVisible) this.props.onVisible(this.hasSeen);

      }
      if(isVisible) this.hasSeen = 1;

      if(this.props.once){
        if(isVisible || this.hasSeen){
          return this.props.once();
        }else{
          return this.props.fallback();
        }
      }

      let visibleClass = (isVisible) ? 'visible' : 'invisible';
      let hasSeenClass = (this.hasSeen) ? 'has-seen' : '';
      return (<div className={className +' is-'+visibleClass+' '+hasSeenClass}>{children}</div>)
    }
    }
    </VisibilitySensor>
  );


  }
}

export default IsVisible
