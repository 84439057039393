import React, { Component } from 'react';

/*

By default:
- will poll for size changes every 1 second
- size values are based on the rendered div's parent node's first child

<WatchSize>
{
({ size }) => <div>Size: {size.width} x {size.height}</div>
}
</WatchSize>

-------------

Options:
- Change interval for polling
- Set interval to 0 to only run once
- Can use callback to set the ref to size from specific dom element

<WatchSize interval={2000}>
{
({ size, sizeRefCallback }) => <div ref={(el)=>sizeRefCallback(el)}>Size: {size.width} x {size.height}</div>
}
</WatchSize>

*/

class WatchSize extends Component {

	 timer=0; // Interval for watching

	$parent = false; // DOM element of the parent node
	$placeholder = false; // DOM element for placeholder sizer
	$el = false; // DOM element for actual child

	constructor(props) {
    super(props);
    this.state = {w:-1,h:-1}; // -1 to determine if it hasn't run yet
  }
  
  componentDidMount() {

	  if(this.$placeholder){
	  	this.$parent = this.$placeholder.parentElement;
	  	this.setState({
	  		w : this.$placeholder.offsetWidth,
	  		h: (this.$parent) ? this.$parent.offsetHeight : this.$placeholder.offsetHeight
	  	},()=>{
	  		this.startListener();
	  	})
	  }

	  
	
	}
	
	componentWillUnmount() {
	    this.stopListener();
	  }

	  startListener = () => {

        let int = (this.props.hasOwnProperty('interval')) ? this.props.interval : 1000;
        if(int === 0 || int === false) return;
        this.timer = setInterval(this.listen,int);

    }


    stopListener = () => {

        if(this.timer) clearInterval(this.timer);

    }
	  
	listen = () => {

		let $el = (this.$el) ? this.$el : this.$placeholder;
		if(!$el){
			$el = (this.$parent) ? this.$parent.firstChild : false;

			//console.log('No $el, used',$el);
		}
		if(!$el) return

		let w = $el.offsetWidth;
		let h = $el.offsetHeight;

		let sizeChanged = ((w != this.state.w) || (h != this.state.h));

		if(sizeChanged){
			//console.log($el,`was ${this.state.w}x${this.state.h}`,`now ${w}x${h}`);
			this.setState({
				w:w,
				h:h
			});
		}

	}

	sizeRefCallback = (el) => {

		this.$el = el;

	}
  
  render() {

  	if( this.state.w == -1){
  		return <div ref={(el) => this.$placeholder = el} />
  	}

    return (
      this.props.children({
      	size: {
      		width:this.state.w,
      		height:this.state.h
      	},
      	sizeRefCallback : this.sizeRefCallback
      })
    );
  }
}

export default WatchSize;
