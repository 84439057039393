import React, { Component } from 'react';


class LoadProgress extends Component {

  render () {

  	let progress = this.props.progress;
  	if(this.props.progress < 100) progress = `0${progress}`;
  	if(this.props.progress < 10) progress = `0${progress}`;

    return (
		<div className="Item Item--loader"><div className="Item-dot" /><div className="Item-head">{progress} / 100</div></div>
    )
  }
}

export default LoadProgress
