import React, {Component} from 'react'
import Data from '../../_core/models/Data';
import SiteModifier from '../../_core/components/SiteModifier';
import Link from '../../_core/components/Link';
import {withStore} from '../../_core/utils/Store';
import Radio from '../../components/Radio';
import ClosePlayer from '../../components/ClosePlayer';


class Player extends Component {

 
  render () {

    return (
      <Data>
    {
      ({PLAYER,SHOWPLAYER}) => {
          
      

          return (
          <React.Fragment>
            {(()=>{

              if(SHOWPLAYER){
               
                return  <SiteModifier modifier={'player'} auto key={'showplayer'} />
              }else{
                return ( <SiteModifier demodifier={'player'} auto key={'noplayer'} />)
              }

            })()}
            <div className="Item Item--player">
              <div className="Item-content">
              {(()=>{

                  

                  if(PLAYER == 'RADIO'){

                    return <Radio />
                  }else{

                      if(PLAYER){

                        let playerData = JSON.parse(PLAYER);

                        return  (
                            <React.Fragment>
                              <div className="Item-iframe"><iframe src={(SHOWPLAYER) ? playerData.src + '&autoplay=1' : null} key={(SHOWPLAYER) ? 'playiframe'+playerData.src : 'noplayiframe'} width={`100%`} height={`60px`} allow="autoplay" /></div>
                              <div className="Item-more"><Link to={playerData.more}>Info & Tracklist</Link></div>
                            </React.Fragment>
                        )
                      }else{
                        return (<div>[[ NO PLAYER ]]</div>)
                      }

                    

                  }

              })()}
              </div>
              <div className="Item-close">
                <ClosePlayer />
              </div>
            </div>
          </React.Fragment>
          )
      }   
    }
    </Data>
    )
    
  }
}



export default withStore(Player);