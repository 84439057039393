import React, {Component} from 'react'
import SiteModifier from '../../_core/components/SiteModifier'

class Burger extends Component {

  constructor() {
    super()

  }

  render () {

    let Tag = this.props.tag || SiteModifier;
    let modifier = this.props.modifier || 'default';


    return (
      <Tag toggle={'menu'} detoggle={'no-menu'}>
        <div className={"Burger Burger--"+modifier}>
          <span className="Burger-line Burger-line--top" />
          <span className="Burger-line Burger-line--middle" />
          <span className="Burger-line Burger-line--bottom" />
          <span className="Burger-line Burger-line--x1" />
          <span className="Burger-line Burger-line--x2" />
        </div>
      </Tag>

    );

  }
}

export default Burger
