import React, { Component } from 'react';

import Link from '../Link';

import cookieAPI from './_cookieAPI.js';
import CookieToggle from './CookieToggle';
import CookieTable from './CookieTable';
import Cookies from './Cookies';

import './Cookies.css';

class CookieManager extends Component {

	constructor(props) {
		super(props);
		this.state = {};
	}


	/*

		RENDER

	*/

	render() {

		let com = this;

		let necessaryCookies = this.props.necessaryCookies || [];
		let optionalCookies = this.props.optionalCookies || [];

		return(
		<Cookies>
			
			{({cookieAPI,cookies,cookieSettings})=>{


				return (
					<div className="Cookie-manager">
					<h1>Cookies</h1>
					<p><Link to={'/'}>&larr; Back to home</Link></p>
					<p>Cookies are small files of letters and numbers that we store on your browser or the hard drive of your device. Like most websites, we use cookies on our website for our legitimate interests. There are different types of cookies that we use for different purposes; however generally speaking cookies help us to distinguish you from other users of our website, which allows us to provide you a better experience and also allows us to improve our website.</p>

<p>You can block cookies at any time by activating the setting on your browser that allows you to refuse some or all cookies. However, if you do block cookies there may be parts of our website that you will be unable to access or that will not function properly.</p>

<p>Our cookies help to:</p>

<ul>
<li>Make our website work as you would expect</li>
<li>Remember your settings during and between visits</li>
<li>Improve your and other users' experiences of our website</li>
<li>Allow you to sign up to marketing and updates if you have requested to do so</li>
<li>Improve the speed/security of our website</li>
</ul>
<h2>Strictly necessary cookies</h2>
<p>These are cookies that are essential for the operation of our website. They include, for example, cookies that enable you to log into secure areas of our website.</p>
					
					<table className="Cookie-table Cookie-table--auto">
					<tbody>
					<tr>
					   <th>Cookie</th>
					   <th>Description</th>
					   <th>Expiry</th>
					 </tr>
					 <tr>
								<td>{cookieSettings.AGREE_KEY}</td>
								<td>Records whether the user has consented to analytics cookies</td>
								<td>1 Year</td>
							</tr>
							{necessaryCookies.map((k,ix)=>{

								return (
									<tr key={`cookieinfo_${ix}`}>
										<td>{k.name}</td>
										<td>{k.description}</td>
										<td>{k.expiry}</td>
										
									</tr>
								)

							})}
					</tbody>
					</table>

					<h2>Analytical/performance cookies</h2>
					<p>They allow us to recognise and count the number of visitors and to see how visitors move around our website when they are using it. This helps us to improve the way our website works, for example, by ensuring that users are finding what they are looking for easily.</p>

					<p><strong>We do not allow Google to use or share the data about how you use the site.</strong></p>

					{(()=>{
						if(optionalCookies.length){
							return (
								<table className="Cookie-table Cookie-table--auto">
									<tbody>
									<tr>
									   <th>Cookie</th>
									   <th>Description</th>
									   <th>Expiry</th>
									 </tr>
									 
										{optionalCookies.map((k,ix)=>{

											return (
												<tr key={`optionalcookieinfo_${ix}`}>
													<td>{k.name}</td>
													<td>{k.description}</td>
													<td>{k.expiry}</td>
													
												</tr>
											)

										})}
									</tbody>
								</table>
							)
						}
					})()}
					
					<div className="Cookie-section"><CookieToggle /></div>

					<h2>Cookies in use</h2>
					<p>The table below lists the client-side cookies that this website has installed on your browser. This does not include server-side cookies that are installed by our web hosts and network providers to allow the proper delivery of content. Toggle optional cookies off to remove these files.</p>
					<CookieTable />

					<p><Link to={'/'}>&larr; Back to home</Link></p>
					
					</div>
				)

			}}

		</Cookies>)


	
		

	}
}

export default CookieManager;