import React, { Component } from 'react';
import Swiper from '../Swiper';

class CoverFlow extends Component {

  render () {

    let loop = (this.props.hasOwnProperty('loop')) ? this.props.loop : true;
    let slidesPerView = 5;
    if(window.innerWidth < 1100) slidesPerView = 4;
    if(window.innerWidth < 900) slidesPerView = 3;
    if(window.innerWidth < 500) slidesPerView = 2;

    if(this.props.hasOwnProperty('sliderSize')){
        if(this.props.sliderSize == 'small'){
            slidesPerView = slidesPerView / 2;
        }
    }

    slidesPerView = Math.max(2,slidesPerView);

    const params = {
      effect: 'coverflow',
      grabCursor: true,
      centeredSlides: true,
      slidesPerView: slidesPerView,
      coverflowEffect: {
        rotate: 20,
        stretch: 0,
        depth: 100,
        modifier: 1,
        slideShadows : true,
      },
      preloadImages: false,
      // Enable lazy loading
      /*lazy: {
        loadPrevNext: true,
        loadPrevNextAmount : 4,
        loadOnTransitionStart : true,
      },*/
      /*observer : true,
      observeSlideChildren : true,*/
      watchSlidesVisibility :false,
      slideToClickedSlide: true,
      //freeMode: true,
      //freeModeSticky: true,
      spaceBetween: 30,
      loop:false,
      pagination: {
        el: '.swiper-pagination',
      },
      initialSlide:0
    }

    let items = [...this.props.items]

    if(loop && items.length > 3){
        params.initialSlide = items.length
        items = items.concat(items);
    }
 
  return(
    <Swiper params={{...params}}>
      {items.map(

          (item,ix) => (
            <div key={`slide_${ix}`}>{item}</div>
          )

      )}
    </Swiper>
  )
  }
}

 
export default CoverFlow;