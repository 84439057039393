import React, { Component } from "react";
//import TweenLite from "gsap/TweenLite";
//import {Expo} from "gsap/EasePack";
import ViewportProgress from "../../components/ViewportProgress";
import { withRouter } from "react-router-dom";

// Flag for ScrollUpdateURI to check if scroller is animating and not update the URI
// TODO: Allow components to communicate without global
window.SCROLLING_BUSY = false;

/*

Add this to index.html
<script src="//cdnjs.cloudflare.com/ajax/libs/gsap/2.0.2/TweenMax.min.js"></script>
<script src="//cdnjs.cloudflare.com/ajax/libs/gsap/2.0.2/easing/EasePack.min.js"></script>
<script src="//cdnjs.cloudflare.com/ajax/libs/gsap/2.0.2/plugins/ScrollToPlugin.min.js"></script>    

*/

const TweenLite = window.TweenLite;
const Expo = window.Expo;

/*

ScrollTo - scroll the .Site pos when the page changes

*/

function getOffset(el) {
  const rect = el.getBoundingClientRect();
  return {
    left: rect.left + window.scrollX,
    top: rect.top + window.scrollY,
  };
}

class ScrollTo extends Component {
  animating = 0;
  stopListening;

  cur = "";

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    let _this = this;
    setTimeout(function () {
      _this.animate(_this.props);
    }, 50);

    if (this.props.useHistory) {
      //console.log(this.props.history);
      this.backListener = this.props.history.listen((location) => {
        //console.log(this.props.history.action);

        if (this.props.history.action === "POP") {
          //console.log('ignoring');
        } else {
          if (this.props.ignore) {
            let shouldIgnore = this.props.ignore(location, this.props.history);
            //console.log('should ignore',shouldIgnore);
            if (!shouldIgnore) {
              let anchor = this.anchor();
              //console.log('anchor',anchor);
              if (anchor) {
                this.animate({ ...this.props, to: window.location.hash });
              } else {
                window.scrollTo(0, 0);
              }
            }
          } else {
            let anchor = this.anchor();

            if (anchor) {
              this.animate({ ...this.props, to: window.location.hash });
            } else {
              window.scrollTo(0, 0);
            }
          }
        }
      });
    }

    window.TweenLite = TweenLite;
  }

  componentWillUnmount() {
    // Unbind listener
    window.SCROLLING_BUSY = 0;
    if (this.props.useHistory) this.backListener();
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.useHistory) return;

    const currentPage = this.props.location.pathname;
    const nextPage = nextProps.location.pathname;

    let href = window.location.href;

    //alert('props');

    if (nextProps.auto) {
      if (href != this.cur || this.props.checkAuto != nextProps.checkAuto) {
        this.cur = href;

        this.auto();
      }
    } else {
      if (
        this.props.key != nextProps.key ||
        this.props.to != nextProps.to ||
        this.props.update != nextProps.update
      ) {
        this.animate(nextProps);
      }
    }
  }

  anchor = () => {
    // Look for a matching anchor
    let hash = window.location.hash || "";

    let found = 0;
    if (hash) {
      found = document.getElementById(hash.replace("#", ""));
    }

    return found;
  };

  auto = () => {
    // Look for a matching anchor
    let hash = window.location.hash || "";

    let found = this.anchor();

    let props = { ...this.props };

    if (found) {
      props.to = hash;
    } else {
      props.pos = 0;
    }

    // Animate if matching element or no match and no hash
    if (found && this.props.minDistance) {
      let dist = Math.abs(found.getBoundingClientRect().top); // Not absolute. Top s relative to scroll, so can be used as distance)
      if (dist <= this.props.minDistance) return;
    }
    if (found || (!found && !hash)) this.animate(props);
  };

  animate = (props) => {
    const _this = this;

    if (window.SCROLLING_BUSY) return;

    if (props.to == "#") return;

    //if(_this.animating) return;

    let scroller = props.scroller
      ? document.querySelector(props.scroller)
      : window;
    let duration = props.hasOwnProperty("duration") ? props.duration : 1.5;

    let offset = parseInt(props.offset) || 0;
    if (typeof props.offset == "function") offset = props.offset;
    let to = props.to || props.pos;

    if (offset && typeof to == "string" && to.match("#")) {
      let el = document.querySelector(props.to);
      let pos = getOffset(el).top;

      let containerHeight = window.innerHeight;
      let offsetAmt = typeof offset == "function" ? offset(el) : offset;

      to = pos - offsetAmt;
    } else if (offset && typeof to == "number") {
      let offsetAmt = typeof offset == "function" ? offset(null) : offset;
      to -= offsetAmt;
    }

    window.SCROLLING_BUSY = 1;
    _this.animating = 1;

    setTimeout(function () {
      TweenLite.to(scroller, duration, {
        scrollTo: { y: to, autoKill: false },
        ease: Expo.easeInOut,
        onComplete: function () {
          window.SCROLLING_BUSY = 0;
          _this.animating = 0;
        },
      });
    }, 100);
  };

  render() {
    return <div ref="el">{this.props.children}</div>;
  }
}

// ScrollUpdateURI
export const ScrollUpdateURI = withRouter(
  class ScrollUpdateURIComponent extends Component {
    render() {
      let uri = this.props.uri;

      let ignore = this.props.hasOwnProperty("ignore")
        ? this.props.ignore()
        : false;

      return (
        <ViewportProgress
          startAt={window.innerHeight}
          debugoff
          distance={window.innerHeight * 0.5}
          capProgressoff={false}
          onProgress={({ progress, pos, travelled, dist }) => {
            if (window.SCROLLING_BUSY || ignore) return;
            if (progress && progress < 1 && pos < window.innerHeight * 0.5) {
              if (window.location.pathname + window.location.hash != uri) {
                // Flag so it doesn animate
                window.SCROLLING_BUSY = true;
                this.props.history.push(uri);
                setTimeout(() => {
                  window.SCROLLING_BUSY = false;
                }, 50);
              }
            }
          }}
        >
          {this.props.children}
        </ViewportProgress>
      );
    }
  }
);

export default withRouter(ScrollTo);
