import React, {Component} from 'react'

import {withStore} from '../../utils/Store';


class Entry extends Component {

  index=[];
  lookUp = {};

  makeIndex = (items) => {

      const _this = this;


      items.forEach((i) => {

          _this.index.push(parseInt(i.id))
          _this.lookUp['id_'+i.id] = i;

      });



  }
  render () {

      const items = this.props.store.get('entries');

      if(!items) return null;

      if(!this.index.length) this.makeIndex(items);



      const _this = this;


      const ids = this.props.id;
      if(!ids) return null;

      if(!ids.filter) return null;

      let matches;

      if(ids.length){
        matches = (ids.filter((i) => {
        return (_this.index.indexOf(parseInt(i)) != -1)
        }) ).map((i) => (_this.lookUp['id_'+i]));
      }else{
        matches = [...items];
      }
      

      let filtered;

      if(this.props.filter){

        filtered = matches.filter((i,ix) => this.props.filter(i,ix));

      }else{

        filtered = matches;

      }

      let response;



      if(this.props.render){

        response = filtered.map((item,ix) => {

        let rendered = _this.props.render(item,ix);
        if(Array.isArray(rendered)) return rendered;
        return {...rendered,key:ix}


      });

      }

      if(this.props.results){

          response = _this.props.results(filtered);
         

      }


    return response;
  }
}

export default withStore(Entry)
