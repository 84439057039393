import React, {Component} from 'react'
import settings from '../../../_settings';

let txt = document.createElement("textarea");

function decodeHtml(html) {
 
    txt.innerHTML = html;
    return txt.value;
}

class PageTitle extends Component {
	title ='';	
  render () {
    if(typeof this.props.title == 'undefined') return null;
  	if(this.props.title == this.title) return null;
  	this.title = this.props.title;
    window.document.title = decodeHtml((this.props.title) ? settings.SITENAME + ' — ' +this.props.title : settings.SITENAME);
    return null;
  }
  
}

export default PageTitle
