import React, { Component } from 'react';

import Content from '../_core/components/Content';


class Index extends Component {

	constructor(props) {
		super(props);
	}

	componentDidMount() {

	}

	componentWillUnmount() {

	}


	/*

		RENDER

	*/

	render() {

		


		return (

			<div className="Template Template--error">
				<Content>
					<h1>Page Not Found</h1>
					<p>We can't find that page. Try our <a href="/">Home Page</a> instead.</p>
				</Content>
			</div>

		);

	}
}

export default Index;
