import React, { Component } from 'react';


class ScrollModifier extends Component {

  direction = 'down';
  changedDirectionAt=0;
  lastScroll=0;
  scrolled=0;
  reversed=0;
  atTop=0;

  constructor(props) {
    super(props);
  }

  componentDidMount() {

    window.addEventListener('scroll',this.scroll)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll',this.scroll)
  }

  scroll = () => {

    let threshold = 10;
    
    // We're scrolling - modify the site
    if(window.scrollY > this.lastScroll && !this.scrolled){
      this.scrolled = 1;
      window.site.modify('scrolled');
    }

    // Reverse: Remove the scrolled modifier if we go backwards
    if(!this.props.hasOwnProperty('reverse') || this.props.hasOwnProperty('reverse') && this.props.reverse){
      if(window.scrollY < this.lastScroll && this.scrolled){
        this.scrolled = 0;
        window.site.deModify('scrolled');
        
      }
    // Else: remove scrolled modifier if we get back to top
    }else{
      if((window.scrollY <= threshold) && this.scrolled){
         this.scrolled = 0;
          window.site.deModify('scrolled');
      }
    }

    // Near the top - set the 'scrollTop' modifier
    if(window.scrollY <= threshold && !this.atTop){
      this.atTop = 1;
      window.site.modify('scrollTop');
    }

    // Not at the top any more
    if(window.scrollY > threshold && this.atTop){
        this.atTop = 0;
        window.site.deModify('scrollTop');
    }

    // Start scrolling up, change direction
    if((window.scrollY < this.lastScroll) && this.direction == 'down' && this.scrolled){
        this.changedDirectionAt = window.scrollY;
        this.direction = 'up';
        window.site.modify('reverse');
    }

    // Start scrolling down, change direction
    if((window.scrollY > this.lastScroll) && this.direction == 'up' && this.scrolled){
        this.changedDirectionAt = window.scrollY;
        this.direction = 'down';
        window.site.deModify('reverse');
    }



    this.lastScroll = window.scrollY;

  }

  /*

    RENDER

  */

  render() {

    return (
      
      this.props.children || null

    
    );

  }
}

export default ScrollModifier;