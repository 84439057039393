import React, { Component } from 'react';

//import {PageElement,Template,SiteModifier,Content} from '../_core/components';

import PageElement from '../_core/components/PageElement';
import Template from '../_core/components/Template';
import Content from '../_core/components/Content';
import SiteModifier from '../_core/components/SiteModifier';
import FadeIn from '../_core/components/FadeIn';
import Link from '../_core/components/Link';
import Toggle from '../_core/components/Toggle';
import Icon from '../_core/components/Icon';

// Models
import {Asset,Entry,Data,SetData} from '../_core/models';

// Project
import * as Project from '../components';



class Default extends Component {

	constructor(props) {
		super(props);
	}

	componentDidMount() {

	}

	componentWillUnmount() {

	}

	/*

		RENDER

	*/

	render() {

		let {entry} = this.props;
		if(!entry) return null;

		let blocks = entry.blocks || [];

		let isHome = entry.uri == '/';

	
		return (
	
				<Template modifiers={[entry.style,entry.type,entry.template]} setTitle={false} title={entry.title}>

				<FadeIn duration={0.4} delay={0.1} key={entry.id}>
				
				{/* Reset styles */}
				<SiteModifier auto demodifier={'scrolled'} />
				{(()=>{

	                if(isHome){
	                  return (<SiteModifier auto modifier={'home'} />);
	                }else{
	                	return (<SiteModifier auto demodifier={'home'} />);
	                }

	              })()}

				{/* Template */}
		
					{/* Title */}
					<div className="Template-head">
						<div className="Container">
							<Content>
								<h1>{ entry.pageTitle || entry.title }</h1>
							</Content>
						</div>
					</div>
					
					<div className="Template-intro">
						<div className="Container">
							{(()=>{

					            if(entry.tags && entry.tags.length){

					              return (
					              <div className="Template-tags">
					                <ul className="List List--tags">
					                <Entry id={entry.tags} render={

					                    (tag) => {

					                      

					                        return (<li><Link to={tag.uri}>{tag.title}</Link></li>)


					                    }
					                  } />
					                  </ul>
					              </div>
					              )

					            }

					        })()}
							<Content html={entry.text} />
							
						</div>
					</div>

				
							
							<Data>
							{
								({entries,menus,FILTERS}) => {

									let source = entry.source;

									let items = [];
									if(source == 'childPages') items = entries.filter(e=>(e.parent == entry.id));
									if(source == 'relatedPages') items = entries.filter(e=>(entry.related.indexOf(e.id) != -1));
									if(source == 'taggedWith'){
										items = entries.filter(e=>{
											if(!e.hasOwnProperty('tags')) return false;
											if(!e.tags.length) return false;

											let found = e.tags.filter(t=>(entry.related.indexOf(t) != -1));
											
											return found.length > 0;
										});
									}
									if(source == 'all') items = entries;

								
									
									
							
											let blockItems = items.map(i => {
												
											return ({
												
												linkTo : [i.id],
												image : [],
												inheritContentFromLink : true,
												callToAction : entry.callToAction
												})}
											);

											let blocks = [];
											blocks.push(
												{
													type : 'default',
													items : blockItems,
													itemSize : 'onequarter',
													itemStyle : 'default'
												}
											);

											return (
												<div className="Template-blocks">
													<div className="Container">
														<Project.Blocks blocks={[...blocks]} entry={entry} modifiers={[]} key={JSON.stringify(items)} />
													</div>
												</div>
											);

										
								}
							}
							</Data>
					
				</FadeIn>
				</Template>
		)

	}
}

export default Default;
