import React, { Component } from "react";

// Core
import Link from "../_core/components/Link";
import Data from "../_core/models/Data";
import ScrollTo from "../_core/components/ScrollTo";
import Content from "../_core/components/Content";
import ScrollModifier from "../_core/components/ScrollModifier";
import SiteModifier from "../_core/components/SiteModifier";

// Components
import Burger from "../components/Burger";
import Nav from "../components/Nav";
import IconNav from "../components/IconNav";
import Logo from "../components/Logo";
import Main from "../components/Main";
import Article from "../components/Article";
import Panel from "../components/Panel";
import Overlay from "../components/Overlay";
import OpacityScreen from "../components/OpacityScreen";
import Footer from "../components/Footer";
import ContactForm from "../components/ContactForm";
import ScrollManager from "../components/ScrollManager";
import Live from "../components/Live";
import Player from "../components/Player";
import GlobalTicker from "../components/GlobalTicker";
import Search from "../components/Search";

class Layout extends Component {
  footer = false;

  constructor(props) {
    super(props);
  }

  setFooter = (el) => {
    this.footer = el;
    this.setState({ footer: el });
  };

  /*

		RENDER

	*/

  render() {
    return (
      <div data-layout className="Site-page">
        {/*
					Add ready modifier to close intro
				*/}
        <SiteModifier modifier={"open"} auto={true} delay={1700} />

        {/*
					Scroll direction modifier
				*/}
        <ScrollModifier reverse={true} />

        {/*
					Scroll manager - listen to page changes and decide whether to jump to top
				*/}
        <ScrollManager />

        {/*
					Logo - back to home
				*/}
        <div className="Site-header">
          <Link to={"/"}>
            <Logo />
          </Link>
        </div>

        {/*
					Live - now playing
				*/}
        <div className="Site-live">
          <Live />
        </div>

        {/*
					Player - Radioco player or media iframe
				*/}
        <div className="Site-player">
          <Player />
        </div>

        {/*
					Burger - show and hide nav
				*/}
        <div className="Site-burger">
          <Burger />
        </div>

        {/*
					Search - search site
				*/}
        <div className="Site-search">
          <Search />
        </div>

        {/*
					Article - journal reader
				*/}
        <div className="Site-article">
          <Article />
        </div>

        {/*
					Panel - panel template
				*/}
        <div className="Site-panel">
          <Panel />
        </div>

        {/*
					Overlay - overlay template
				*/}
        <div className="Site-overlay">
          <Overlay />
        </div>

        {/*
					Menu - main navigation
				*/}
        <div className="Site-menu">
          <Nav label="main" modifier="main" />
        </div>

        {/*
					Main content - layer controlling scroll bad background content
				*/}
        <div className="Site-main">
          <Main />
        </div>

        {/*
					Ticker
				*/}
        <div className="Site-ticker">
          <GlobalTicker />
        </div>

        {/*
					Header nav - main navigation
				*/}
        <div className="Site-header-nav">
          <Nav label="main" modifier="header" />
        </div>

        {/*
					Shortcuts nav - quick buttons
				*/}
        <div className="Site-shortcuts-nav">
          <Nav label="shortcuts" modifier="shortcuts" />
        </div>

        {/*
					Footer - nav and contact
				*/}
        <div
          className="Site-footer-screen"
          style={
            this.footer ? { height: `${this.footer.offsetHeight}px` } : null
          }
        >
          <OpacityScreen
            dist={
              this.footer ? this.footer.offsetHeight : window.innerHeight * 0.6
            }
            start={window.innerHeight}
            reverse={true}
          />
        </div>
        <div className="Site-footer" ref={this.setFooter}>
          <div className="Site-footer-content">
            <Footer />
          </div>
          <div className="Site-footer-upper">
            <div className="Site-footer-left">
              <div className="Site-footer-taco">
                <span>A</span>{" "}
                <a href={"https://taco.org.uk"} target={"_blank"}>
                  <img src={`/ui/img/taco.png`} alt={"Taco!"} />
                </a>{" "}
                <span>Project</span>
              </div>
              <div className="Site-footer-address">
                <p>Taco!</p>
                <p>2 Cygnet Square</p>
                <p>Thamesmead</p>
                <p>London</p>
                <p>SE2 9FA</p>
              </div>
            </div>
            <div className="Site-footer-nav">
              <Nav label="footer" modifier="sitemap" labels={false} />
            </div>
          </div>

          <div className="Site-footer-credit">
            <div>
              <Nav label="legal" modifier="legal" />
            </div>
            <div>&copy; {new Date().getFullYear()} RTM</div>
            <div>
              <Content modifier={"credit"}>
                <p>
                  Site by <a href="https://un-work.co">un—work</a>
                </p>
              </Content>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Layout;
