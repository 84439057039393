import React, { Component } from 'react';

// Project
import Blocks from '../components/Blocks';


class BlocksTemplate extends Component {

	constructor(props) {
		super(props);
	}

	componentDidMount() {

	}

	componentWillUnmount() {

	}


	/*

		RENDER

	*/

	render() {

		let entry = this.props.entry;
		let blocks = entry.blocks || [];

		let content = [...blocks];
	

		return (
			<React.Fragment>
				<Blocks blocks={content} entry={entry} modifiers={['debug']} />
			</React.Fragment>

		);

	}
}

export default BlocksTemplate;
