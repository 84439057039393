import React, { Component } from 'react';
import Content from '../_core/components/Content';

// Project
import CookieManager from '../_core/components/Cookie/CookieManager';


class Default extends Component {

	constructor(props) {
		super(props);
	}

	componentDidMount() {

	}

	componentWillUnmount() {

	}


	/*

		RENDER

	*/

	render() {
	

		return (
			<div className="Template Template--basic">
				<div className="Template-content">
					<CookieManager />
				</div>
			</div>
		);

	}
}

export default Default;
