import GoogleAnalytics from 'react-ga';
import theme from './theme.js'

const IS_PRODUCTION = (window.location.hostname.match('rtm.fm'));
const d = new Date();
let CACHEBUSTER = (!IS_PRODUCTION) ? '?nc='+d.getFullYear()+d.getMonth()+d.getDate()+d.getHours() : '?nc='+d.getTime();

CACHEBUSTER += 'v.0.3'; // Version tag for cache flushing

const TEST_PRODUCTION = false;
const HOST = (IS_PRODUCTION || TEST_PRODUCTION) ?  '//admin.rtm.fm/data/' : '/data/' ;

// Shows are split across how many JSON buckets?
const SHOW_BUCKETS = 10;
const shows = [...Array(SHOW_BUCKETS)].map((i,ix)=>{
	return(
		{
			name:`shows${ix + 1}`,
			src: HOST+`shows-${ix + 1}.json`+CACHEBUSTER,
			type:'json',
			subset : 'data'
		}
	)}
	);

const settings = {

	SITENAME : 'RTM',
	BRAND : 'RTM.FM',
	STRAPLINE : 'RTM',
	GA_ID : 'UA-114130238-2', // Analytics ID
	STYLES : theme,
	MANIFEST : [
		{
			name:'pages',
			src: HOST+'pages.json'+CACHEBUSTER,
			type:'json',
			subset : 'data'
		},
		{
			name:'assets',
			src: HOST+'assets.json'+CACHEBUSTER,
			type:'json',
			subset : 'data'
		},
		{
			name:'structures',
			src: HOST+'menus.json'+CACHEBUSTER,
			type:'json',
			subset : 'data'
		},
		{
			name:'globals',
			src: HOST+'globals.json'+CACHEBUSTER,
			type:'json',
			subset : 'data',
			transformer : (data) => (data[0])
		}

	].concat(shows),
	GOOGLE_MAPS_API_KEY : 'XXX' // Maps ID

	
}

if(settings.GA_ID){
	GoogleAnalytics.initialize({

	    trackingId: settings.GA_ID,
	    debug: true

	});
}


export default settings;


