import React, { Component } from 'react';
import '../../_core/helpers/String'; // .decodeHTML prototype
import Link from '../../_core/components/Link';
import SiteModifier from '../../_core/components/SiteModifier';
import Html from '../../_core/components/Html';
import Data from '../../_core/models/Data';
import Blocks from '../Blocks';


class GlobalTicker extends Component {

  render () {

  	let modifier = this.props.modifier || 'default';

    return (
		<Data>
		{
			({globals}) => {

        if(globals.ticker && globals.ticker.length){

          return <Blocks blocks={[{type:'background',style:'alt'},{type:'global',block:globals.ticker}]} />

        }else{
          return null;
        }
        
        return (<div>GLOBALS{JSON.stringify(globals)}</div> );
      }

		}
		</Data>
    )
  }
}

export default GlobalTicker
