import React, { useState } from "react";

// Project
import Blocks from "../components/Blocks";

const dummyEntry = {
  id: 0,
  title: "Guide",
};

/*

Block types



*/

const dummyShortText = '<p>Lorem ipsum dolor sit <a href="#">amet</a></p>';
const dummyMediumText =
  "<p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem.</p><p>Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur?</p>";
const dummyLongText = `
<h1>Heading 1</h1>
<p>Sed <strong>bold</strong> ut <a href=#">Link</a> perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem.</p>
<p>Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur?</p>
<h2>Heading 2</h2>
<p>Dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem.</p>
<h3>Heading 3</h3>
<p>Laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem.</p>
<h4>Heading 4</h4>
<p>Veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem.</p>
<h5>Heading 5</h5>
<p>Ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem.</p>
<ul>
<li>Lorem ipsum dolor sit amet error sit voluptatem accusantium doloremque laudantium, totam rem aperiam</li>
<li>Dolor sit amet error sit voluptatem accusantium doloremque laudantium, totam rem aperiam</li>
<li>Consectetur, adipisci velit Dolor sit amet error sit voluptatem accusantium doloremque laudantium, totam rem aperiam</li>
</ul>

<ol>
<li>Lorem ipsum dolor sit amet error sit voluptatem accusantium doloremque laudantium, totam rem aperiam</li>
<li>Dolor sit amet error sit voluptatem accusantium doloremque laudantium, totam rem aperiam</li>
<li>Consectetur, adipisci velit Dolor sit amet error sit voluptatem accusantium doloremque laudantium, totam rem aperiam</li>
</ol>

<p>Dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem.</p>
`;

const dummyImage = [37975];
const dummyImages = [4643, 38246, 37975];

const dummyGallery = [
  {
    image: [dummyImages[0]],
    caption: '<p>Cover fill <a href="/">With Internal Link</a></p>',
    showCaption: true,
    aspect: "cover",
    linkTo: [10],
  },
  {
    image: [dummyImages[1]],
    caption:
      '<p>Fit fill <a href="https://google.com">With External Link</a></p>',
    showCaption: true,
    aspect: "fit",
    linkTo: [],
  },
];

const dummyItems = [
  {
    linkTo: [29658],
    inheritContentFromLink: 1,
    image: [],
    heading: "",
    text: "",
    customLink: "",
    callToAction: "Inherit (Audio)",
    playerLink:
      "https://www.mixcloud.com/widget/iframe/?hide_cover=1&light=1&feed=%2FRTM_FM%2Fthe-inner-ear-tony-whittingham-120321%2F",
    rollOverText: dummyShortText,
  },
  {
    linkTo: [27346],
    inheritContentFromLink: 1,
    image: [],
    heading: "",
    text: "",
    customLink: "",
    callToAction: "Inherit (Show)",
    playerLink: "",
    rollOverText: "",
  },
  {
    linkTo: [2194],
    inheritContentFromLink: "",
    image: [34336],
    heading: "Heading",
    text: "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc elementum vitae eros a suscipit.</p>",
    customLink: "",
    callToAction: "Custom",
    playerLink: "",
    rollOverText: "",
  },
];

const dummyLink = [10];

const dummyLinks = [
  {
    text: "Link to home",
    linkTo: [10],
    customLink: "",
  },
  {
    text: "External Link",
    linkTo: null,
    customLink: "https://google.com",
  },
];

const blockTypes = [
  {
    title: "Title Card",
    handle: "titleCard",
    fields: [
      {
        title: "Pre-Header",
        handle: "preHeader",
        options: [
          {
            title: "No Pre-Header",
            value: "",
          },
          {
            title: "With Pre-Header",
            value: "Lorem ipsum dolder",
          },
        ],
      },
      {
        title: "Heading",
        handle: "heading",
        value: "Title Card",
      },
      {
        title: "Post-Header",
        handle: "postHeader",
        options: [
          {
            title: "No Post-Header",
            value: "",
          },
          {
            title: "With Post-Header",
            value: "Adipscing elit dolor",
          },
        ],
      },
      {
        title: "Image",
        handle: "image",
        value: [38247],
      },
      {
        title: "Text Colour",
        handle: "textColour",
        options: [
          {
            title: "Default",
            value: "",
          },
          {
            title: "Black",
            value: "#000000",
          },
        ],
      },
      {
        title: "Internal Link",
        handle: "linkTo",
        options: [
          {
            title: "No Internal Link",
            value: [],
          },
          {
            title: "With Internal Link",
            value: dummyLink,
          },
        ],
      },
      {
        title: "Custom Link",
        handle: "customLink",
        options: [
          {
            title: "No Custom Link",
            value: "",
          },
          {
            title: "With Custom Link",
            value: "https://google.com",
          },
        ],
      },
    ],
  },
  {
    title: "Items",
    handle: "default",
    fields: [
      {
        handle: "heading",
        value: "Heading",
      },
      {
        handle: "text",
        value: dummyMediumText,
      },
      {
        title: "Layout",
        handle: "layout",
        options: [
          {
            title: "Default",
            value: "default",
          },
          {
            title: "Scroller",
            value: "scroller",
          },
        ],
      },
      {
        title: "Align",
        handle: "align",
        options: [
          {
            title: "Default",
            value: "default",
          },
          {
            title: "Centre",
            value: "centre",
          },
          {
            title: "Right",
            value: "right",
          },
        ],
      },
      {
        title: "Links",
        handle: "links",
        options: [
          {
            title: "No links",
            value: [],
          },
          {
            title: "Links",
            value: dummyLinks,
          },
        ],
      },
      {
        title: "Items",
        handle: "items",
        options: [
          {
            title: "No items",
            value: [],
          },
          {
            title: "With Items",
            value: dummyItems,
          },
        ],
      },
      {
        title: "Item Size",
        handle: "itemSize",
        options: [
          {
            title: "Full",
            value: "full",
          },
          {
            title: "Half",
            value: "half",
          },
          {
            title: "One Third",
            value: "onethird",
          },
        ],
      },
      {
        title: "Item Style",
        handle: "itemStyle",
        options: [
          {
            title: "Default",
            value: "default",
          },
          {
            title: "Feature",
            value: "feature",
          },
        ],
      },
    ],
  },
  {
    title: "Text",
    handle: "text",
    fields: [
      {
        handle: "text",
        value: dummyLongText,
      },
      {
        title: "Align",
        handle: "align",
        options: [
          {
            title: "Default",
            value: "default",
          },
          {
            title: "Centre",
            value: "centre",
          },
          {
            title: "Right",
            value: "right",
          },
        ],
      },
    ],
  },
  {
    title: "Image",
    handle: "image",
    fields: [
      {
        handle: "image",
        value: dummyImage,
      },
      {
        handle: "caption",
        value: dummyShortText,
      },
      {
        title: "Show Caption",
        handle: "showCaption",
        options: [
          {
            title: "No Caption",
            value: false,
          },
          {
            title: "Show Caption",
            value: true,
          },
        ],
      },
      {
        title: "Size",
        handle: "size",
        options: [
          {
            title: "Full",
            value: "full",
          },
          {
            title: "Half",
            value: "half",
          },
        ],
      },
      {
        title: "Internal Link",
        handle: "linkTo",
        options: [
          {
            title: "No Internal Link",
            value: [],
          },
          {
            title: "With Internal Link",
            value: dummyLink,
          },
        ],
      },
      {
        title: "Custom Link",
        handle: "customLink",
        options: [
          {
            title: "No Custom Link",
            value: "",
          },
          {
            title: "With Custom Link",
            value: "https://google.com",
          },
        ],
      },
    ],
  },
  {
    title: "Gallery",
    handle: "gallery",
    fields: [
      {
        handle: "heading",
        value: "Heading",
      },
      {
        handle: "text",
        value: dummyMediumText,
      },
      {
        title: "Align",
        handle: "align",
        options: [
          {
            title: "Default",
            value: "default",
          },
          {
            title: "Centre",
            value: "centre",
          },
          {
            title: "Right",
            value: "right",
          },
        ],
      },
      {
        title: "Images",
        handle: "images",
        value: dummyGallery,
      },
      {
        title: "Link",
        handle: "linkTo",
        options: [
          {
            title: "No link",
            value: [],
          },
          {
            title: "Link",
            value: dummyLink,
          },
        ],
      },
      {
        title: "Custom Link",
        handle: "customLink",
        options: [
          {
            title: "No custom link",
            value: "",
          },
          {
            title: "Custom link",
            value: "https://google.com",
          },
        ],
      },
      {
        title: "Call to Action",
        handle: "callToAction",
        options: [
          {
            title: "No CTA",
            value: "",
          },
          {
            title: "With CTA",
            value: "Call to action",
          },
        ],
      },
      {
        title: "CTA Before",
        handle: "ctaBefore",
        options: [
          {
            title: "Yes",
            value: true,
          },
          {
            title: "No",
            value: false,
          },
        ],
      },
      {
        title: "Autoplay",
        handle: "autoplay",
        options: [
          {
            title: "No autoplay",
            value: false,
          },
          {
            title: "Autoplay",
            value: true,
          },
        ],
      },
      {
        title: "Size",
        handle: "size",
        options: [
          {
            title: "Default",
            value: "default",
          },
          {
            title: "Fullscreen",
            value: "fullscreen",
          },
        ],
      },
    ],
  },
  {
    title: "Ticker",
    handle: "ticker",
    fields: [
      {
        title: "Links",
        handle: "links",
        value: [
          {
            text: "Text only item",
            linkTo: [],
            customLink: "",
            playerLink: "",
          },
          {
            text: "Internal link",
            linkTo: [10],
            customLink: "",
            playerLink: "",
          },
          {
            text: "Custom link",
            linkTo: [],
            customLink: "https://google.com",
            playerLink: "",
          },
          {
            text: "Player link",
            linkTo: [],
            customLink: "",
            playerLink:
              "https://www.mixcloud.com/widget/iframe/?hide_cover=1&light=1&feed=%2FRTM_FM%2Fthe-inner-ear-tony-whittingham-120321%2F",
          },
        ],
      },
    ],
  },
  {
    title: "Embed",
    handle: "embed",
    fields: [
      {
        title: "Source",
        handle: "src",
        options: [
          {
            title: "Audio source",
            value:
              "https://www.mixcloud.com/widget/iframe/?hide_cover=1&light=1&feed=%2FRTM_FM%2Fthe-inner-ear-tony-whittingham-120321%2F",
          },
          {
            title: "Video Source",
            value: "https://www.youtube.com/embed/a4mv1aLrJvM",
          },
        ],
      },
      {
        title: "Size",
        handle: "size",
        options: [
          {
            title: "Audio",
            value: "audio",
          },
          {
            title: "Video",
            value: "video",
          },
        ],
      },

      {
        title: "Use Custom Aspect",
        handle: "useCustom",
        options: [
          {
            title: "No",
            value: false,
          },
          {
            title: "Yes",
            value: true,
          },
        ],
      },
      {
        title: "Width",
        handle: "width",
        options: [
          {
            title: "16",
            value: 16,
          },
          {
            title: "4",
            value: 4,
          },
        ],
      },
      {
        title: "Height",
        handle: "height",
        options: [
          {
            title: "9",
            value: 9,
          },
          {
            title: "3",
            value: 3,
          },
        ],
      },
    ],
  },
  {
    title: "Scroller",
    handle: "scroller",
    fields: [
      {
        handle: "heading",
        value: "Heading",
      },
      {
        title: "Mirrored",
        handle: "mirrored",
        options: [
          {
            title: "No",
            value: false,
          },
          {
            title: "Yes",
            value: true,
          },
        ],
      },
      {
        title: "Items",
        handle: "items",
        value: [
          6899, 6744, 6581, 6522, 6436, 6271, 6155, 6046, 5958, 5840, 5733,
          5640, 5549, 5442,
        ],
      },
    ],
  },
  {
    title: "RadioCo Schedule",
    handle: "radiocoSchedule",
    fields: [
      {
        handle: "scheduleId",
        value: "es1b04d61",
      },
    ],
  },
  {
    title: "Eventbrite Widget",
    handle: "eventbriteWidgetButton",
    fields: [
      {
        handle: "eventId",
        value: 52766401728,
      },
      {
        handle: "callToAction",
        value: "Call to Action",
      },
      {
        title: "Promo Code",
        handle: "promoCode",
        options: [
          {
            title: "No Promo",
            value: "",
          },
          {
            title: "With Promo",
            value: "PROMO1",
          },
        ],
      },
      {
        title: "Align",
        handle: "align",
        options: [
          {
            title: "Default",
            value: "default",
          },
          {
            title: "Centre",
            value: "centre",
          },
          {
            title: "Right",
            value: "right",
          },
        ],
      },
    ],
  },
];

/*

type : Items
layout: default
align: default
size: half
style: default

*/

const dummyBlocks = [
  {
    text: "<pre>&#x1f447; RadioCo Schedule\n-------------------------------</pre>",
    align: "default",
    type: "text",
  },
  {
    scheduleId: "es1b04d61",
    type: "radiocoSchedule",
  },
  {
    text: "<pre>&#x1f447; Eventbrite Widget Button\n-------------------------------</pre>",
    align: "default",
    type: "text",
  },
  {
    eventId: 52766401728,
    callToAction: "Get Tickets",
    promoCode: "",
    align: "centre",
    type: "eventbriteWidgetButton",
  },
  {
    text: "<pre>&#x1f447; Items\n---------------------\nlayout: default\nalign: default\nsize: full\nstyle: default</pre>",
    align: "default",
    type: "text",
  },
  {
    layout: "default",
    heading: "Items block",
    align: "default",
    text: "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc elementum vitae eros a suscipit. Integer nunc eros, scelerisque gravida placerat ac, placerat non ex. Sed aliquet diam vel augue hendrerit, non blandit nisl posuere.</p>",
    links: [
      {
        text: "Link to home",
        linkTo: [10],
        customLink: "",
      },
    ],
    items: [
      {
        linkTo: [29658],
        inheritContentFromLink: 1,
        image: [],
        heading: "",
        text: "",
        customLink: "",
        callToAction: "Inherit (Audio)",
        playerLink: "",
      },
      {
        linkTo: [27346],
        inheritContentFromLink: 1,
        image: [],
        heading: "",
        text: "",
        customLink: "",
        callToAction: "Inherit (Show)",
        playerLink: "",
      },
      {
        linkTo: [2194],
        inheritContentFromLink: "",
        image: [34336],
        heading: "Heading",
        text: "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc elementum vitae eros a suscipit.</p>",
        customLink: "",
        callToAction: "Custom",
        playerLink: "",
      },
    ],
    itemSize: "full",
    itemStyle: "default",
    type: "default",
  },
  {
    text: "<pre>&#x1f447; Items\n---------------------\nlayout: default\nalign: default\nsize: half\nstyle: default</pre>",
    align: "default",
    type: "text",
  },
  {
    layout: "default",
    heading: "",
    align: "default",
    text: "",
    links: [],
    items: [
      {
        linkTo: [29658],
        inheritContentFromLink: 1,
        image: [],
        heading: "",
        text: "",
        customLink: "",
        callToAction: "",
        playerLink: "",
      },
      {
        linkTo: [27346],
        inheritContentFromLink: 1,
        image: [],
        heading: "",
        text: "",
        customLink: "",
        callToAction: "",
        playerLink: "",
      },
    ],
    itemSize: "half",
    itemStyle: "default",
    type: "default",
  },
  {
    text: "<pre>&#x1f447; Items\n---------------------\nlayout: default\nalign: default\nsize: One Third\nstyle: default</pre>",
    align: "default",
    type: "text",
  },
  {
    layout: "default",
    heading: "",
    align: "default",
    text: "",
    links: [],
    items: [
      {
        linkTo: [27346],
        inheritContentFromLink: 1,
        image: [],
        heading: "",
        text: "",
        customLink: "",
        callToAction: "",
        playerLink: "",
      },
      {
        linkTo: [29658],
        inheritContentFromLink: 1,
        image: [],
        heading: "",
        text: "",
        customLink: "",
        callToAction: "",
        playerLink: "",
      },
      {
        linkTo: [7801],
        inheritContentFromLink: 1,
        image: [],
        heading: "",
        text: "",
        customLink: "",
        callToAction: "",
        playerLink: "",
      },
    ],
    itemSize: "onethird",
    itemStyle: "default",
    type: "default",
  },
  {
    text: "<pre>&#x1f447; Items\n---------------------\nlayout: default\nalign: default\nsize: half\nstyle: feature</pre>",
    align: "default",
    type: "text",
  },
  {
    layout: "default",
    heading: "",
    align: "default",
    text: "",
    links: [],
    items: [
      {
        linkTo: [29658],
        inheritContentFromLink: 1,
        image: [],
        heading: "",
        text: "",
        customLink: "",
        callToAction: "",
        playerLink: "",
      },
      {
        linkTo: [27346],
        inheritContentFromLink: 1,
        image: [],
        heading: "",
        text: "",
        customLink: "",
        callToAction: "",
        playerLink: "",
      },
    ],
    itemSize: "half",
    itemStyle: "feature",
    type: "default",
  },
  {
    text: "<pre>&#x1f447; Items\n---------------------\nlayout: scroller\nalign: * ignored in scroller mode *\nsize: * ignored in scroller mode *\nstyle: default</pre>",
    align: "default",
    type: "text",
  },
  {
    layout: "scroller",
    heading: "",
    align: "default",
    text: "",
    links: [],
    items: [
      {
        linkTo: [27178],
        inheritContentFromLink: 1,
        image: [],
        heading: "",
        text: "",
        customLink: "",
        callToAction: "",
        playerLink: "",
      },
      {
        linkTo: [5418],
        inheritContentFromLink: 1,
        image: [],
        heading: "",
        text: "",
        customLink: "",
        callToAction: "",
        playerLink: "",
      },
      {
        linkTo: [27178],
        inheritContentFromLink: 1,
        image: [],
        heading: "",
        text: "",
        customLink: "",
        callToAction: "",
        playerLink: "",
      },
    ],
    itemSize: "full",
    itemStyle: "default",
    type: "default",
  },
  {
    text: "<pre>&#x1f447; Title Card\n---------------------\nlink: none</pre>",
    align: "default",
    type: "text",
  },
  {
    preHeader: "Pre Header",
    heading: "Heading",
    postHeader: "Post Header",
    image: [38247],
    linkTo: [],
    customLink: "",
    type: "titleCard",
  },
  {
    text: "<pre>&#x1f447; Title Card\n---------------------\nlink: Internal</pre>",
    align: "default",
    type: "text",
  },
  {
    preHeader: "Pre Header",
    heading: "Heading",
    postHeader: "Post Header",
    image: [38323],
    linkTo: [10],
    customLink: "",
    type: "titleCard",
  },
];

const BlocksTemplate = () => {
  const [activeTypeIx, setActiveTypeIx] = useState(0);
  const [activeVariants, setActiveVariants] = useState({});

  let entry = dummyEntry;
  let blocks = dummyBlocks;

  const setTab = (ix) => {
    setActiveTypeIx(ix);
    setActiveVariants({});
  };

  const schema = JSON.parse(JSON.stringify(blockTypes[activeTypeIx]));
  const contentToShow = (schema.fields || []).reduce(
    (obj, field) => {
      if (field.hasOwnProperty("options")) {
        const variantIx = activeVariants.hasOwnProperty(field.handle)
          ? activeVariants[field.handle]
          : 0;
        obj[field.handle] = field.options[variantIx].value;
      } else {
        obj[field.handle] = field.value;
      }

      return obj;
    },
    { type: blockTypes[activeTypeIx].handle }
  );

  const variants = (schema.fields || []).reduce((obj, field) => {
    if (field.hasOwnProperty("options")) obj.push(field);
    return obj;
  }, []);

  const cloned1 = JSON.parse(JSON.stringify(contentToShow));
  const cloned2 = JSON.parse(JSON.stringify(contentToShow));
  return (
    <div style={{ background: "#ccc", paddingTop: `70px` }}>
      <ul className="List List--tabs">
        {blockTypes.map((type, ix) => {
          return (
            <li
              onClick={() => setTab(ix)}
              className={activeTypeIx === ix ? "active" : "inactive"}
            >
              {type.title}
            </li>
          );
        })}
      </ul>
      <ul className="List List--variants">
        {variants.map((variant) => {
          return (
            <li>
              <div>{variant.title}</div>
              <select
                onChange={(event) => {
                  const v = parseInt(event.target.value);
                  const val = {};

                  val[variant.handle] = v;

                  setActiveVariants({ ...activeVariants, ...val });
                }}
              >
                {variant.options.map((o, ix) => (
                  <option value={ix}>{o.title}</option>
                ))}
              </select>
            </li>
          );
        })}
      </ul>
      <div key={JSON.stringify(contentToShow)} style={{ background: "#000" }}>
        <Blocks
          blocks={[
            /*{
                    "text": `<pre>&#x1f447; ${blockTypes[activeTypeIx].title}\n---------------------\n<\/pre>`,
                    "align": "default",
                    "type": "text"
                },*/ contentToShow,
            {
              style: "alt",
              type: "background",
            },
            cloned1,
            {
              style: "none",
              useCustom: true,
              background: "#CCCCCC",
              textColour: "#444444",
              type: "background",
            },
            cloned2,
          ]}
          entry={entry}
          modifiers={["debug"]}
        />
      </div>
    </div>
  );
};

export default BlocksTemplate;
