import React, { Component } from 'react';

import Search from './search';
import Results from './results';


class Default extends Component {

	constructor(props) {
		super(props);
	}

	componentDidMount() {

	}

	componentWillUnmount() {

	}

	/*

		RENDER

	*/

	render() {

		let {entry} = this.props;
		if(!entry) return null;

		let settings = {

			title : entry.title,
			source : 'taggedWith',
			related : [entry.id],
			text : '',
			callToAction : 'More info',
			type:'results',
			style:'results',
			template:'tags',
			filter : (entry) => {
				return entry.type == 'listing'
			}
		}
	
		return (
				
				<Search>
				<Results entry={settings} />
				</Search>
				
		)

	}
}

export default Default;
