import React, {Component} from 'react'

import {withStore} from "../../utils/Store";

import { Link,withRouter } from "react-router-dom";

class Content extends Component {

	nl2br = (str) => {
	    if (typeof str === 'undefined' || str === null) {
	        return '';
	    }
	    let breakTag = '<br/>'
	    return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2');
	  }

	navigate = (event) =>  {


	  let target = (event.target.tagName === 'IMG') ? event.target.parentElement : event.target;
	
	  if (target.tagName === 'A') {
	  	// Internal link?
	  	let href = target.getAttribute('href');
	  	if(!href) return;
	  	let hasExtension = (href.indexOf('.') != -1);
	  	let internalLink = (href.indexOf('/') == 0);
	  	let isHash = (href.indexOf('#') == 0);
	  	let isEmail = (href.indexOf('@') != -1);
	  	let isJavascript = (href.indexOf('javascript:') != -1);
	  	let isTel = (href.indexOf('tel:') != -1);

	  	let newWindow = (!isHash && !isTel && !isEmail && !isJavascript);

	  	let useRouter = ((internalLink || isHash) && !hasExtension);
	    if(useRouter){
	    	
	    	event.preventDefault();
	    	this.props.history.push(target.getAttribute('href'))

	    }else if(newWindow){

	    	event.preventDefault();
	    	// Add noopener attribute https://dev.to/ben/the-targetblank-vulnerability-by-example
	    	window.open(target.getAttribute('href'),'_blank','noopener');
	    }
	  }
	}

	render () {
		
		const mod = (this.props.modifier) ? 'Content--'+this.props.modifier : '';

		  const lookup = (t,id) => {
		  	
		  	let dataSet = t == 'entry' ? this.props.data.entries : this.props.data.assets;

		  	return dataSet.find(item => {
		  		return parseInt(item.id) == parseInt(id)
		  	});
		  }

		  let props = {};
		  let content = null;
		  if(this.props.html) {

		  	let html = this.props.html

		  	if(this.props.nl2br) html = this.nl2br(html);

		  	var pattern = /\{(entry|asset)\:([0-9]+)\:(url)\}/g;
		  	
		  	//var matches = pattern.exec(this.props.html);
		  	//var matches = html.match(pattern)
		  	
		  	let matches;

		  	while ((matches = pattern.exec(this.props.html)) != null) {

		  		var res = lookup(matches[1],matches[2])


		  		if(res){

			  		if(matches[1] == 'entry'){
			  			html = html.replace(matches[0],res.uri);
			  		}else{

			  			let src = res.sizes.small || res.url;

			  			let altPlaceholder = res.filename+'#asset:'+res.id;
			  			let altText = res.title;

			  			html = html.replace(matches[0],src);
			  			html = html.replace(altPlaceholder,altText);

			  		}
		  		}else{
		  			html = html.replace(matches[0],'');
		  		}
		  		

		  	}

		  	props.dangerouslySetInnerHTML = {__html:html};
		  }
		  if(this.props.children && !this.props.html) content = this.props.children;

		  return (
		  	<div onClick={this.navigate} className={'Content '+mod} {...props}>{content}</div>
		  	)

	}

}

/*const  = ({html,modifier,children}) => {
  const mod = (modifier) ? 'Content--'+modifier : '';

  const lookup = (t,id) => {
  	let set = t == 'entry' ? this.props.data.entries : this.props.data.assets
  	return this.props.data.entries.map(item => {
  		return item.id == id
  	})
  }

  let props = {};
  let content = null;
  if(html) {

  	var pattern = /\{(entry|asset)\:([0-9]+)\:(url)\}/g;
  	var matches = pattern.exec(html);
  	//var matches = html.match(pattern)

  	if(matches)
  	{

  		console.log(matches)
  		
  		var res = lookup(matches[1],matches[2])
  		console.log(res)


  	}

  	props.dangerouslySetInnerHTML = {__html:html};
  }
  if(children && !html) content = children;

  return <div className={'Content '+mod} {...props}>{content}</div>

}*/

export default withRouter(withStore(Content));
