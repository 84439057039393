import React, {Component} from 'react'

import {withStore} from '../../_core/utils/Store';


class Playbutton extends Component {

 
  render () {

    let active = (this.props.store.get('PLAYERSRC') == this.props.src) && (this.props.store.get('SHOWPLAYER') == true); 
    let isActive = (active) ? 'is-active' : 'inactive';

    return (
      <div onClick={()=>{

        this.props.store.store('PLAYER',JSON.stringify({
          src : this.props.src,
          more : this.props.more
        }),false);
        this.props.store.store('PLAYERSRC',this.props.src,false);
        this.props.store.store('SHOWPLAYER',!active);

      }} className={isActive+' Playbutton '+this.props.className}>
        {this.props.children}
      </div>
    )
    
  }
}



export default withStore(Playbutton);