import React, {Component} from 'react'

import Icon from '../../components/Icon'

import '../../helpers/Array';

class Pagination extends Component {

  constructor(props) {
		super(props);
		this.state = {page:1};
	}

	nextPage = () => {

		this.setState({page:this.state.page + 1});
	}

  render () {
  	
  	const {items,perPage} = this.props;

  	let page = this.state.page
  	let pages = Math.ceil(items.length / perPage);

  	let paged = [...items];

  	let howMany = perPage * page;
  	if(howMany > items.length) howMany = items.length;
  	

  	let paginated = paged.splice(0,howMany);

  	const output = this.props.render(paginated);

    let loadText = this.props.loadText || 'More';

  	const moreLink = (page < pages) ? <div className="Link Link--more" onClick={this.nextPage}>{loadText}</div> : null

    const lastPage = (page == pages) ? 'Pagination--lastPage' : '';
    return (
    	<div className={`Pagination ${lastPage}`}>
    		{output}
        <div className="Pagination-more">
    		  {moreLink}
        </div>
    	</div>
    	)
  }
}

export default Pagination
