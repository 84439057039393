/**
 * From ReactGA Community Wiki Page https://github.com/react-ga/react-ga/wiki/React-Router-v4-withTracker
 */

import React, { Component } from 'react';
import conf from '../_conf';
import GoogleAnalytics from 'react-ga';

export default function withTracker(WrappedComponent, options = {}) {
  const trackPage = (page) => {

    let settings = conf.get();
    
    if(settings.hasOwnProperty('GA_ID')){
      if(!settings.GA_ID) return;
      GoogleAnalytics.set({
        page,
        ...options
      });
      GoogleAnalytics.pageview(page);

    }
    
  };

  const HOC = class extends Component {
    componentDidMount(props) {
      const page = this.props.location.pathname;

      trackPage(page);
    }

    componentWillReceiveProps(nextProps) {
      let hash = (this.props.location.hash) ? this.props.location.hash : '';
      let nextHash = (nextProps.location.hash) ? nextProps.location.hash : '';

      const currentPage = this.props.location.pathname + hash;
      const nextPage = nextProps.location.pathname + nextHash ;

      if (currentPage !== nextPage) {
        trackPage(nextPage);
      }
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  };

  return HOC;
}