import React, { Component } from 'react';

import Blocks from './blocks';

class Home extends Component {

  render () {

    return (
	
	     <Blocks entry={this.props.entry} />

    )
  }
}

export default Home
