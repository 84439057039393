import React, { Component } from 'react';
import Cookies from './Cookies';

import './Cookies.css';

class CookieToggle extends Component {

	constructor(props) {
		super(props);
		this.state = {};
	}

	componentDidMount() {
		
	

	}




	/*

		RENDER

	*/

	render() {

		let com = this;

		return(
		<Cookies>
			
			{({cookieAPI,cookies,cookieSettings,agreeCookies,declinedCookies})=>{

			

				if(agreeCookies){
					// Agree to cookies. Click to disable
					return <div key={'clear'} onClick={()=>cookieAPI.decline()} className="Cookie-toggle is-active"><span className="Cookie-toggle-label">Optional Cookies Are On</span></div>

				}else{
					// Cookies disabled. Click to enable
					return <div key={'agree'}  onClick={()=>cookieAPI.agree()} className="Cookie-toggle is-inactive"><span className="Cookie-toggle-label">Optional Cookies Are Off</span></div>
				}


			}}

		</Cookies>)
		

	}
}

export default CookieToggle;