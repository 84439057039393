import React, { Component } from "react";

// Breakpoints
import * as Breakpoints from "../../_core/utils/Breakpoints";

// Core
//import {FadeIn,Loader,Image,Form,Html,Link,Base,Map,List,IsVisible,Grid,Media,ModifierSection,Section,Window,Content} from '../../_core/components';

import FadeIn from "../../_core/components/FadeIn";
import Pagination from "../../_core/components/Pagination";
import Loader from "../../_core/components/Loader";
import Image from "../../_core/components/Image";
import Link from "../../_core/components/Link";
import ModifierSection from "../../_core/components/ModifierSection";
import Content from "../../_core/components/Content";
import Media from "../../_core/components/Media";
import Grid from "../../_core/components/Grid";
import Base from "../../_core/components/BaseComponent";
import Html from "../../_core/components/Html";

import { Entry, Asset, Data } from "../../_core/models";
import { Each } from "../../_core/utils";
import lightOrDark from "../../_core/utils/lightOrDark";
import Gallery from "../../_core/components/Gallery";
import Icon from "../../_core/components/Icon";
import Nl2br from "../../_core/components/Nl2br";
import Template from "../../_core/controllers/Template";

// Helpers
import "../../_core/helpers/String";

// Conf
import conf from "../../_core/_conf";

// Project
import OpacityScreen from "../OpacityScreen";
import CoverFlow from "../CoverFlow";
import Slider from "../Slider";
import Logo from "../Logo";
import Playbutton from "../Playbutton";

class Javascript extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const s = document.createElement("script");
    s.type = "text/javascript";
    s.async = true;
    s.src = this.props.src;
    this.instance.appendChild(s);
  }

  render() {
    return <div ref={(el) => (this.instance = el)} />;
  }
}

class Blocks extends Component {
  sections = [];
  componentDidMount = () => {};
  cleanUri = (uri) => {
    let settings = conf.get();
    return uri.replace(settings.CDN_HOSTNAME, "");
  };
  makeBlocks = () => {
    this.section = [];
    let blocks = this.props.blocks || [];

    if (!blocks.length) {
      this.sections = [];
      return;
    }

    // Get the background positions...
    if (blocks[0].type != "background")
      blocks.unshift({
        type: "background",
        style: "none",
      });

    let sections = [];
    let section = -1;

    blocks.forEach((block) => {
      if (block.type == "background") {
        section++;
        sections[section] = { ...block, blocks: [] };
      } else {
        sections[section].blocks.push(block);
      }
    });

    this.sections = sections;
  };
  getBlock = (origBlock, entry, ix) => {
    let _this = this;
    let settings = conf.get();

    let block = { ...origBlock };
    let blockId = block.id || entry.id + "_block_" + ix;

    switch (block.type) {
      case "global":
        if (!block.block.length) return null;
        return (
          <Entry
            id={block.block}
            render={(globalBlock) => (
              <Each
                items={globalBlock.blocks}
                render={(item) => {
                  // Copy size from the current page's block into the global block
                  let b = { ...item, size: block.size };

                  return _this.getBlock(b, entry);
                }}
              />
            )}
          />
        );

      case "anchor":
        return (
          <a
            className="Block Block--anchor"
            id={block.name}
            name={block.name}
          ></a>
        );
      case "divider":
        return <div className="Block Block--divider" id={blockId}></div>;
      case "radiocoSchedule":
        return (
          <div className="Block Block--radiocoSchedule ">
            {/*<Javascript src={`https://embed.radio.co/embeds/schedule/${block.scheduleId ? block.scheduleId : 'es1b04d61'}.js`} />*/}
            <iframe
              src={`https://embed.radio.co/embeds/schedule/${
                block.scheduleId ? block.scheduleId : "es1b04d61"
              }.html`}
              width="100%"
              height="600"
              allow="autoplay"
              scrolling="no"
              style={{ border: "none", overflow: "hidden", height: 600 }}
            ></iframe>
          </div>
        );
      case "eventbriteWidgetButton":
        return (
          <div
            className={
              "Block Block--eventbriteWidgetButton Block--align-" + block.align
            }
          >
            <div
              className={"Link Link--button"}
              id={blockId}
              ref={(el) => {
                let args = {
                  widgetType: "checkout",
                  eventId: `${block.eventId}`,
                  modal: true,
                  modalTriggerElementId: blockId,
                  onOrderComplete: () => {},
                };

                if (block.promoCode) args.promoCode = block.promoCode;

                window.EBWidgets.createWidget(args);
              }}
            >
              <span className="Link-body">{block.callToAction}</span>
            </div>
          </div>
        );
      case "spacer":
        return (
          <div
            data-type={block.type}
            className={"Block Block--" + block.type}
            data-spaces={block.spaces}
          />
        );

      case "play":
        return (
          <div className={"Block Block--play"}>
            <div className="Block-content">
              <Playbutton src={block.playerLink} more={block.more}>
                <div className={"Link Link--button Link--play"}>
                  <span className="Link-body">{block.text}</span>
                </div>
              </Playbutton>
            </div>
          </div>
        );

      case "embed":
        return (
          <div
            className={"Block Block--embed Block--" + block.size}
            data-type={block.type}
          >
            <div className="Block-iframe">
              <iframe
                width={"100%"}
                height={"100%"}
                src={block.src}
                border={"none"}
              />
            </div>
          </div>
        );
      case "titleCard":
        let Wrap = "div";
        let url =
          block.linkTo && block.linkTo.length ? block.linkTo : block.customLink;

        let color =
          block.textColour && block.textColour.length ? block.textColour : null;
        let style = null;
        if (color) style = { color: color };

        if (typeof url != "undefined") {
          if (url && url.length) {
            Wrap = Link;
          }
        }

        return (
          <Wrap className="Block-wrapper" to={url}>
            <div className={`Block Block--${block.type}`} style={style}>
              <div className="Block-content">
                {(() => {
                  if (block.preHeader) {
                    return (
                      <div className="Block-preHeader">{block.preHeader}</div>
                    );
                  }
                })()}
                {(() => {
                  if (block.heading) {
                    return <div className="Block-heading">{block.heading}</div>;
                  }
                })()}
                {(() => {
                  if (block.postHeader) {
                    return (
                      <div className="Block-postHeader">{block.postHeader}</div>
                    );
                  }
                })()}
              </div>
              <div className="Block-media">
                <Media id={block.image} fluid={true} />
              </div>
            </div>
          </Wrap>
        );

      case "ticker":
        if (!block.links.length) return null;

        let repeats =
          block.links.length > 10 ? 1 : Math.ceil(10 / block.links.length);

        return (
          <div className={"Block Block--ticker"} data-type={block.type}>
            <div className="Block-items">
              <ul
                className={"List List--ticker"}
                data-count={block.links.length}
              >
                {[...Array(repeats)].map((i, tix) => (
                  <Each
                    key={"ticker_" + tix}
                    items={block.links}
                    render={(item, ix) => {
                      let tickerItem = {
                        ...item,
                        callToAction: item.text,
                        text: false,
                      };

                      return (
                        <li
                          key={"tick_" + i + "_" + ix}
                          className={"Block-item Block-item--ticker"}
                        >
                          <Link
                            to={
                              item.linkTo && item.linkTo.length
                                ? item.linkTo
                                : item.customLink
                            }
                          >
                            {item.text}
                          </Link>
                        </li>
                      );
                    }}
                  />
                ))}
              </ul>
            </div>
          </div>
        );

      case "scroller":
      case "default":
      case "items":
        //var style = block.style || 'default';

        let items =
          block.type == "scroller"
            ? block.items.map((i) => ({
                linkTo: [i],
                image: [],
                inheritContentFromLink: true,
                callToAction: "More Info",
              }))
            : block.items;

        let displayAs =
          block.type == "scroller" || block.layout == "scroller"
            ? "scroller"
            : "grid";

        let sliderSize = block.sliderSize || "large";
        return (
          <div
            className={
              "Block Block--section Block--align-" +
              block.align +
              "  Block--" +
              block.style +
              " Block--" +
              block.layout
            }
            data-type={block.type}
          >
            <div className="Block-body">
              <div className="Block-text">
                {(() => {
                  if (block.heading) {
                    return (
                      <div className="Block-heading">
                        <div>
                          <Nl2br text={block.heading} />
                        </div>
                      </div>
                    );
                  }
                })()}
                {(() => {
                  if (block.text) {
                    return (
                      <div className="Block-content">
                        <Content html={block.text} />
                      </div>
                    );
                  }
                })()}

                {(() => {
                  if (block.links && block.links.length) {
                    return (
                      <div className="Block-links">
                        <div>
                          <Grid modifiers={["links"]}>
                            <Each
                              items={block.links}
                              render={(item) => {
                                let uri =
                                  item.customLink && item.customLink.length
                                    ? item.customLink
                                    : item.linkTo;
                                return (
                                  <div className="Grid-item">
                                    <Link
                                      to={uri}
                                      className={
                                        "Link Link--callToAction Link--" +
                                        block.style
                                      }
                                      deactivate={"#nav"}
                                    >
                                      <span className="Link-body">
                                        {item.text}
                                      </span>
                                    </Link>
                                  </div>
                                );
                              }}
                            />
                          </Grid>
                        </div>
                      </div>
                    );
                  }
                })()}
              </div>

              <div className="Block-items">
                {(() => {
                  if (displayAs == "scroller") {
                    return (
                      <div className="Block-coverflow">
                        <CoverFlow
                          sliderSize={sliderSize}
                          loop={block.mirrored || null}
                          items={items.map((item, ix) => (
                            <div>
                              {BlockItem(
                                { ...item, style: "coverflow", number: ix + 1 },
                                (item) => {
                                  console.log(item);
                                  return (
                                    <div className="Item Item--coverflow">
                                      {(() => {
                                        if (item.image && item.image.length) {
                                          if (item.playerLink) {
                                            return (
                                              <div className="Item-media Item-media--play">
                                                <Link to={item.url}>
                                                  <Media
                                                    id={item.image}
                                                    fluid={false}
                                                    lazy={true}
                                                    key={"img_" + item.id}
                                                    ratio={6 / 6}
                                                  />
                                                </Link>
                                                <Playbutton
                                                  src={item.playerLink}
                                                  more={item.uri}
                                                >
                                                  <div className="Item-media-play">
                                                    <Icon glyph type={"play"} />
                                                  </div>
                                                </Playbutton>
                                              </div>
                                            );
                                          } else {
                                            return (
                                              <Link
                                                to={item.url}
                                                className="Item-media Item-media--default"
                                              >
                                                <Media
                                                  id={item.image}
                                                  fluid={false}
                                                  lazy={true}
                                                  key={"img_" + item.id}
                                                  ratio={6 / 6}
                                                />
                                              </Link>
                                            );
                                          }
                                        }
                                      })()}
                                      <div className="Item-content">
                                        <div className="Item-title">
                                          <h1>{item.heading}</h1>
                                        </div>

                                        {(() => {
                                          if (item.tags && item.tags.length) {
                                            return (
                                              <div className="Item-tags">
                                                <ul className="List List--tags">
                                                  <Entry
                                                    id={item.tags}
                                                    render={(tag) => {
                                                      return (
                                                        <li>
                                                          <Link to={tag.uri}>
                                                            {tag.title}
                                                          </Link>
                                                        </li>
                                                      );
                                                    }}
                                                  />
                                                </ul>
                                              </div>
                                            );
                                          }
                                        })()}
                                        {(() => {
                                          if (item.callToAction) {
                                            return (
                                              <div className="Item-link">
                                                <Link to={item.url}>
                                                  {item.callToAction}
                                                </Link>
                                              </div>
                                            );
                                          }
                                        })()}
                                      </div>
                                    </div>
                                  );
                                }
                              )}
                            </div>
                          ))}
                        />
                      </div>
                    );
                  } else {
                    return (
                      <ul
                        className={
                          "List List--grid List--" +
                          block.itemSize +
                          " List--style-" +
                          block.itemStyle
                        }
                      >
                        <Pagination
                          items={block.items}
                          perPage={20}
                          loadText="Load More"
                          render={(items) => (
                            <Each
                              items={items}
                              render={(item, ix) => {
                                return (
                                  <li
                                    className={
                                      "List-item List-item--" + block.itemSize
                                    }
                                  >
                                    {BlockItem({
                                      ...item,
                                      style: block.itemStyle,
                                      number: ix + 1,
                                    })}
                                  </li>
                                );
                              }}
                            />
                          )}
                        ></Pagination>
                      </ul>
                    );
                  }
                })()}
              </div>
            </div>
          </div>
        );

      case "gallery":
        return (
          <div
            className={`Block Block--swiper Block--align-${block.align} Block--size-${block.size}`}
          >
            <div className="Block-text">
              {(() => {
                if (block.heading) {
                  return (
                    <div className="Block-heading">
                      <div>
                        <Nl2br text={block.heading} />
                      </div>
                    </div>
                  );
                }
              })()}
              {(() => {
                if (block.text) {
                  return (
                    <div className="Block-content">
                      <Content html={block.text} />
                    </div>
                  );
                }
              })()}

              {(() => {
                if (block.callToAction && block.ctaBefore) {
                  return (
                    <div className="Block-callToAction Block-callToAction--before">
                      {BlockItem(block, (item) => (
                        <Link className="Link Link--button" to={item.url}>
                          {item.callToAction}
                        </Link>
                      ))}
                    </div>
                  );
                }
              })()}
            </div>
            <div className="Block-media">
              <div className="Block-swiper">
                <Slider
                  autoPlay={block.autoPlay || null}
                  preventClicksPropagation={false}
                  items={block.images.map((item, ix) => {
                    let galleryLinkStyle = `galleryLink Item--galleryLink Item--swiper Item--${
                      item.image.width > item.image.height
                        ? "landscape"
                        : "portrait"
                    }`;

                    let Wrap = "div";
                    let url =
                      item.linkTo && item.linkTo.length
                        ? item.linkTo
                        : item.customLink;

                    if (typeof url != "undefined") {
                      if (url && url.length) {
                        Wrap = Link;
                      }
                    }

                    if (false && item.linkTo && item.linkTo.length) {
                      return BlockItem({
                        ...item,
                        text: item.caption,
                        caption: null,
                        inheritContentFromLink: true,
                        style:
                          block.size == "fullscreen"
                            ? `fullscreen Item--${galleryLinkStyle}`
                            : galleryLinkStyle,
                        number: ix + 1,
                      });
                    } else {
                      return (
                        <div
                          className={`Item Item--gallery Item--swiper Item--${
                            block.size == "fullscreen"
                              ? "fullscreen"
                              : "regularSize"
                          } Item--${
                            item.image.width > item.image.height
                              ? "landscape"
                              : "portrait"
                          }`}
                          key={`slider_slide_${ix}`}
                        >
                          <Wrap to={url}>
                            <div className="Item-media">
                              <Media id={item.image[0]} fluid={true} />
                            </div>
                          </Wrap>
                          {(() => {
                            if (item.caption) {
                              return (
                                <div className="Item-body">
                                  <div className="Content">
                                    <Html html={item.caption} />
                                  </div>
                                </div>
                              );
                            }
                          })()}
                        </div>
                      );
                    }
                  })}
                />
              </div>
            </div>
            {(() => {
              if (block.callToAction && !block.ctaBefore) {
                return (
                  <div className="Block-callToAction Block-callToAction--after">
                    {BlockItem(block, (item) => (
                      <Link className="Link Link--button" to={item.url}>
                        {item.callToAction}
                      </Link>
                    ))}
                  </div>
                );
              }
            })()}
          </div>
        );

      case "image":
        return (
          <Asset
            id={block.image}
            render={(asset) => {
              let ratio = asset.width / asset.height;
              if (block.aspect && block.aspect == "portrait") ratio = 4 / 6;
              if (block.aspect && block.aspect == "landscape") ratio = 6 / 4;

              let Wrap = "div";
              let url =
                block.linkTo && block.linkTo.length
                  ? block.linkTo
                  : block.customLink;

              if (typeof url != "undefined") {
                if (url && url.length) {
                  Wrap = Link;
                }
              }

              return (
                <div
                  key={block.image[0]}
                  className={
                    `Block Block--image Block--${
                      block.size || "full"
                    } Block--orientation-` + asset.orientation
                  }
                  data-type={block.type}
                >
                  <Wrap className="Block-wrapper" to={url}>
                    <div className="Block-content">
                      <div
                        className={"Item Item--gallery Item--" + block.aspect}
                      >
                        <div className="Item-media">
                          <Media asset={asset} fluid={false} ratio={ratio} />
                        </div>
                        {(() => {
                          if (block.showCaption) {
                            return (
                              <div className="Item-body">
                                <div className="Item-caption">
                                  <Content
                                    modifier="caption"
                                    html={block.caption}
                                  />
                                </div>
                              </div>
                            );
                          }
                        })()}
                      </div>
                    </div>
                  </Wrap>
                </div>
              );
            }}
          />
        );

      case "text":
        return (
          <div
            className={"Block Block--text Block--align-" + block.align}
            id={blockId}
            data-type={block.type}
          >
            {(() => {
              if (block.heading) {
                return (
                  <div className="Block-head">
                    <Content>
                      <h1>
                        <Nl2br html={block.heading} />
                      </h1>
                    </Content>
                  </div>
                );
              }
            })()}
            <div className="Block-content">
              <Content html={block.text} />
            </div>
          </div>
        );

      case "item":
        return (
          <Each
            items={block.items}
            render={(item, ix) => {
              return <div>{item.title}</div>;
            }}
          />
        );

      default:
        return (
          <div
            data-type={block.type}
            id={blockId}
            className={"Block Block--" + block.type + " Block--" + block.size}
          >
            {JSON.stringify(block)}
          </div>
        );
    }
  };
  render() {
    this.makeBlocks();
    //console.log('blocks',this.sections);

    let { sections } = this;
    let modifiers = this.props.modifiers || [];
    let entry = this.props.entry || false;

    return (
      <Base family="Blocks" modifiers={modifiers}>
        {sections.map((section, s_ix) => {
          let style = section.style || "default";
          let color = null;

          if (section.hasOwnProperty("useCustom") && section.useCustom) {
            color = {
              background: section.background,
              color: section.textColour,
            };
          }

          let modifier = "color-default";
          if (color) {
            style += " Blocks-section--";
            let lod = lightOrDark(section.background);
            style += lod;

            modifier = lod;
          }

          return (
            <div
              className={"Blocks-section Blocks-section--" + style}
              key={"block_section_" + s_ix}
              style={color}
              data-background={section.background}
              data-style={style}
            >
              <ModifierSection modifier={modifier}>
                {section.blocks.map((block, ix) => {
                  return (
                    <React.Fragment key={"block_frag_" + ix}>
                      {this.getBlock(block, entry, ix)}
                    </React.Fragment>
                  );
                })}
              </ModifierSection>
            </div>
          );
        })}
      </Base>
    );
  }
}

function Block(block) {}

export const BlockItem = (item, Callback) => {
  //return <div>{JSON.stringify(item)}</div>;

  if (typeof Callback == "undefined") Callback = Item;

  if (item.linkTo && item.linkTo.length) {
    return (
      <Entry
        id={item.linkTo}
        render={(entry) => {
          let itemProps = {
            ...item,
            url: entry.uri,
            image:
              item.inheritContentFromLink && !item.image.length
                ? entry.thumbnail
                : item.image,
            heading:
              item.inheritContentFromLink && !item.heading
                ? entry.title
                : item.heading,
            text:
              item.inheritContentFromLink && !item.text
                ? entry.metaDescription
                : item.text,
            style: item.style,
            playerLink:
              item.inheritContentFromLink && !item.playerLink
                ? entry.playerLink
                : item.playerLink,
            tags: entry.tags,
          };

          return <Callback {...itemProps} />;
        }}
      />
    );
  } else {
    let itemProps = {
      ...item,
      url: item.customLink,
      image: item.image,
      heading: item.heading,
      text: item.text,
      style: item.style,
      playerLink: item.playerLink,
    };

    return <Callback {...itemProps} />;
  }
};

export const Item = (item) => {
  //return <div>{JSON.stringify(item)}</div>;

  let number = item.number || 0;
  let Wrap = item.url ? Link : "div";

  return (
    <div data-number={number} className={"Item Item--" + item.style}>
      {(() => {
        if (item.image && item.image.length) {
          if (item.playerLink) {
            return (
              <Wrap to={item.url}>
                <div className="Item-media Item-media--play">
                  <Media id={item.image} fluid={false} lazy={true} />
                  <Playbutton src={item.playerLink} more={item.url}>
                    <div className="Item-media-play">
                      <Icon glyph type={"play"} />
                    </div>
                  </Playbutton>
                </div>
              </Wrap>
            );
          } else {
            return (
              <Wrap to={item.url}>
                <div className="Item-media">
                  <Media id={item.image} fluid={false} lazy={true} />
                </div>
              </Wrap>
            );
          }
        }
      })()}
      <div className="Item-content-wrap">
        {(() => {
          if (item.playerLink) {
            return (
              <div className="Item-content-play">
                <Playbutton src={item.playerLink} more={item.url}>
                  <div className="Item-media-play">
                    <Icon glyph type={"play"} />
                  </div>
                </Playbutton>
              </div>
            );
          }
        })()}
        <div className="Item-content">
          {(() => {
            if (item.heading) {
              return (
                <div data-number={number} className="Item-title">
                  <h1>{item.heading}</h1>
                </div>
              );
            }
          })()}

          {(() => {
            if (item.text) {
              return (
                <div className="Item-body">
                  <Content html={item.text} />
                </div>
              );
            }
          })()}
          {(() => {
            if (item.tags && item.tags.length) {
              return (
                <div className="Item-tags">
                  <ul className="List List--tags">
                    <Entry
                      id={item.tags}
                      render={(tag) => {
                        return (
                          <li>
                            <Link to={tag.uri}>{tag.title}</Link>
                          </li>
                        );
                      }}
                    />
                  </ul>
                </div>
              );
            }
          })()}
          {(() => {
            if (item.callToAction) {
              return (
                <div className="Item-link">
                  <Link to={item.url}>
                    <span>{item.callToAction}</span>
                  </Link>
                </div>
              );
            }
          })()}
        </div>
      </div>
    </div>
  );
};

export default Blocks;
/*
  {blocks.map((block) => {

            if(block.type == 'text')
            {
              return (
                <Content html={block.body}/>
                )
            } else if(block.type == 'columns')
            {
              return (
              <div>
              <Content html={block.body}/>
              <div className="Template-column">
                <Content html={block.leftColumn}/>
              </div>
              <div className="Template-column">
                <Content html={block.rightColumn}/>
              </div>
              </div>)
            }

            })}
*/
