import 'babel-polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
//import createHistory from 'history/createBrowserHistory'

import {
  BrowserRouter as Router,
  Route
} from 'react-router-dom';

// Config getter/setter
import conf from './_conf.js';

// Tracker HOC for page views
import withTracker from './utils/withTracker';
import Store from './utils/Store';

export default function bootstrap(App,settings) {
	
	// Set the settings
	conf.set(settings);

	let rootId = settings.hasOwnProperty('ROOT_ID') ? settings.ROOT_ID : 'root';

	// Set up the base router with tracker and load the App passed in
	//const history = createHistory()

	ReactDOM.render(<Router><Store><Route component={withTracker(App)} /></Store></Router>, document.getElementById(rootId));
}
