import React, {Component} from 'react'

import {withStore} from '../../utils/Store';

import Data from '../Data';


class Asset extends Component {

  index=[];
  lookUp = {};

  makeIndex = (items) => {

      const _this = this;


      items.forEach((i) => {

          _this.index.push(i.id)
          _this.lookUp['id_'+i.id] = i;

      });



  }

  render () {

      const _this = this;

      return (
      <Data require={['assets']} observe={['assets']}>
        {({assets}) => {

            let items = assets;
            if(!items) return null;

            if(!_this.index.length) _this.makeIndex(items);

             const ids = _this.props.id;

            if(_this.props.debug) console.log('ID',ids,items);
            if(!ids) return null;

            if(!ids.filter) return null;

            const matches = (ids.filter((i) => {
              let id = parseInt(i);
              return (_this.index.indexOf(id) != -1)
            }) ).map((i) => (_this.lookUp['id_'+i]));

            let filtered;

            if(_this.props.filter){

              filtered = matches.filter((i,ix) => _this.props.filter(i,ix));

            }else{

              filtered = matches;

            }

            if(_this.props.debug) console.log('FOUND',filtered,_this.lookUp);

            let response;

            if(_this.props.render){

              response = filtered.map((item,ix) => {

              let rendered = _this.props.render(item,ix);

              return {...rendered,key:ix}


            });

            }

            if(_this.props.results){

                response = _this.props.results(filtered);
               

            }


          return response;

        }}
      </Data>
      )

      /*const items = this.props.store.get('assets');
    
      if(!items) return null;

      if(!this.index.length) this.makeIndex(items);



      


      const ids = this.props.id;
      if(this.props.debug) console.log('ID',ids,items);
      if(!ids) return null;

      if(!ids.filter) return null;

      const matches = (ids.filter((i) => {
        let id = parseInt(i);
        return (_this.index.indexOf(id) != -1)
      }) ).map((i) => (_this.lookUp['id_'+i]));

      let filtered;

      if(this.props.filter){

        filtered = matches.filter((i,ix) => this.props.filter(i,ix));

      }else{

        filtered = matches;

      }

      if(this.props.debug) console.log('FOUND',filtered,_this.lookUp);

      let response;



      if(this.props.render){

        response = filtered.map((item,ix) => {

        let rendered = _this.props.render(item,ix);

        return {...rendered,key:ix}


      });

      }

      if(this.props.results){

          response = _this.props.results(filtered);
         

      }


    return response;*/
  }
}

export default Asset
